.content {
  padding: 5px;
  background-color: white;
  width: 60vw;
  
  min-width: 380px;
  max-width: 400px;
  max-height: 662px;
  /* overflow:auto; */
}

.normalLayoutHeight{
  height: 662px;
}

.label {
  text-align: center;
}

.productionTgtStock {
  div {
    display: flex;
    justify-content: center;
    margin: 2px;
  }
}

.lotDivContainer {
  display: block !important;
}
.container {
}

.keyPad {
  height: 300px;
}
