.pageContainer {
  display: flex;
  justify-content: center;
}

.toolBar {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;

  box-shadow: 0 4px 2px -2px rgba(128, 128, 128, 0.377);
}

.accountDivContainer {
  text-align: center;
}

.entityDiv {
  font-size: 20px;
}

.identifyInfo {
  text-align: center;
  font-size: 20px;
}
