.confirmButtonContainer {
  display: flex;
  justify-content: center;
}

.confirmationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius:0px 0px 16px 16px;
  .confirmButton {
    min-height: 80px;
    height: 5vh;
    /*width:60vw;*/
    width: 100%;
    /*max-width:250px;*/
    min-width: 320px;
    font-size: 21px;
    margin: 10px;
  }
  .pauseButton {
    min-height: 80px;
    min-width: 80px;
  }
}
