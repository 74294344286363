.topBar{
    display:flex;
    justify-content: flex-end;
    color:rgb(230, 98, 98);
    cursor:pointer;
    z-index:1;
}

.closeBtn{

   >svg{
    font-size:54px;
    position: absolute;
    right: 8px;
    top: 6px;
   }
}