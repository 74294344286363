.inputbar {
  font-size: 20px;
  text-align: center;
}

.inputBarContainer {
  input {
    padding-left: 36px;
    padding-right: 36px;
  }
  input::placeholder {
    font-size: 20px;
  }
}

.iconContainer {
  position: relative;
  float: right;
  left: -43px;
  top: 10px;
  .iconElement {
    position: absolute;
    font-size: 32px;
  }

  .closeIconElement {
    color: #c73b3b;
    cursor: pointer;
  }
}
