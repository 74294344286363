@use "/src/theme/haxify.scss" as hxf;

.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.optionsContainer {
  /*position: absolute;
  top: 37%;
  transform: translateY(-37%);
  width: 100%;*/
}

.optionsContent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: 20px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  min-width: 220px;
  height: 155px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;

  margin: 10px;
  width:250px;
  overflow: hidden;

}

.elemElementTitle{
  font-size: 19px;
  font-weight: 900;
  width:100%;
 
 text-align: center;
  width:240px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position:relative;
  left:-11px;
  margin-bottom:5px;
}

.elemElement:hover {
  /*background-color: rgba(78, 195, 195, 0.123);*/
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.statusContainer{
    display:flex;
    align-items: center;
    color:black;
    position: absolute;
    bottom: 3px;
    left: -6px;
    justify-content: center;
    width:100%;
}

.statusAvailable{
    color:rgb(48, 90, 228);
    padding-left:5px;
    display:flex;
    align-items: center;
}

.statusUnavailable{
  color:rgb(228, 48, 48);
  padding-left:5px;
  display:flex;
  align-items: center;
}

.unavailableElem{
  background-color: #fff2f2;
  color:rgb(228, 69, 48);
}

.unavailableElem:hover{
  background-color: rgb(252, 236, 236);
}


.statusRunning{
    color:green;
    padding-left:5px;
    display:flex;
    align-items: center;
}

.availableElem{
  background-color: #f2f5ff;
  color:rgb(48, 90, 228);
}
.availableElem:hover{
  background-color: rgb(236, 241, 252);
}


.activeElem{
  background-color: rgb(237, 255, 237);
  color:green;
}


.activeElem:hover{
  background-color: rgb(249, 255, 249);
}

.unavCodeContainer{
  display:flex;
  justify-content: center;
}

.infoContainer{
  text-align:center;
  font-size:15px;
}
.mmPVVContainer{
  font-size:13px;
}

.mmadjProductContainer{
  justify-content: center;
 
    margin-left:5px;
    margin-right:5px;
    align-items: center;
    width:215px;


}

.productCode{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

}

.pvvsLabelContainer{
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.holdingMach{
 
}

.preloadedFromContainer{
  margin:10px;
  padding:10px;
  border-radius:10px;
  border-color:rgb(36, 44, 157);
  border-style:solid;
  border-width:1px;
  font-size:13px;
  display:flex;
  align-items: center;
  background-color: #def0ff;
}

.topInfoContainer{
  display:flex;
  justify-content: center;
}

.cancelPreload{
  color:#bf6666;
  cursor:pointer;
}