@use "/src/theme/haxify.scss" as hxf;

.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.optionsContainer {
  margin-top:20px;
  width: 100%;
}

.prDataContainer{
  border-radius: 10px 10px 10px 10px;
  border-style:solid;
  border-color: rgb(16, 16, 107);
  border-width:1px;
}
.operationNamingContainer{
  border-radius: 10px 10px 0px 0px;
  background-color: rgb(16, 16, 107);
  color:white;
  display:flex;
  justify-content: center;
  font-weight:900;

}

.statusPr{
  display:flex;
  justify-content: center;
}

.optionsContent {

  @media screen and (min-width: 900px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* show it on small screens */
  }
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: -5px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  min-width: 190px;
  height: 100px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;

  margin: 10px;
}

.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
  margin: 10px;
  padding-top: 50px;
}

.tableContainer {
}

.headerTable {
  font-size: 26px;
}

.rowTable {
  flex-wrap: nowrap;
}
