.container {
}

.content {
  padding: 5px;
  background-color: white;
  min-width: 333px;

  max-height: 642px;
  overflow: auto;
}
.elementBtnContainer{
  align-items: center;
  justify-content: center;
  color: white;
  display: flex;
  background-color: #3d3dca;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  width: 60px;
  height: 60px;

}
.bottomAreaContainer {
  > div {
    display: flex;
    justify-content: center;
  }

  background-color: white;


  min-width: 382px;
  max-width: 850px;
  max-height: 660px;
  position: relative;
  top: -15px;
}
.btnOptionInner{
  cursor:pointer;
}

.btnOptionContainer{
  display:flex;
  justify-content: center;
  margin-left:15px;
  margin-right:15px;
}

.btnOptionLabel{
  font-size:13px;
  text-align: center;
}

.btnOptionDisabled{
  background-color: gray !important;
  cursor:not-allowed;
}

.locationSelectorArea {
  display: flex;
  justify-content: center;
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}

.intPopup {
  > div {
    padding-top: 30px;
    /*margin-top:30px;*/
  }
}

.selectedProductInfo {
  width: 70vw;
  min-width: 382px;
  max-width: 850px;
  max-height: 660px;
  background-color: rgb(33, 87, 189);
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
  }
}

.selcetedProductInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.mapInputsPrint{
  
}