@use "/src/theme/haxify.scss" as hxf;

.toolbarBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    padding-right: 10px;
  }
  padding-top: 2px;
  padding-bottom: 2px;
 color:hxf.$hxfDarkCol1;
}
