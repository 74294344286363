.content {
  padding: 5px;
  background-color: white;
  width: 60vw;
  height: 662px;
  min-width: 380px;
  max-width: 400px;
  max-height: 662px;
  /* overflow:auto; */
  @media screen and (max-height:749px){
    height:550px;
  }

}

.contentProductionLocation{
  padding: 5px;
  background-color: white;
}

.label {
  text-align: center;
  margin-bottom:10px;
}

.productionTgtStock {
  margin:10px;
}

.lotDivContainer {
  display: block !important;
}
.container {
}

.keyPad {
  height: 300px;
}
