.imagePrev{
    max-width: 100px;
    max-height: 100px;
}

.hiddenInput{
    visibility:'hidden';
    height:0px;
    width:0px;
}

.selectorInput{
    width:100px;
    height:100px;
    border-radius:10px;
    border-style:solid;
    border-width:1px;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    >div{
        >svg{
            color:#746f97;
            font-size: 60px;
        }
    }
    cursor:pointer;
}

.selectorInput:hover{
    background-color: #322e59;
}

.resetImgIcon{
    cursor: pointer;
    color:#5635b3;
    display:flex;
    align-items: center;
    >svg{

        font-size:60px;
        
    }
}

.container{
    display:flex;
}

.selectorError{
    background-color: #eaafaf;
}