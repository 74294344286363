.container{
    margin:20px;
}

.btnConfirm{
    font-size:30px;
}

.arrPPVVs{
    margin-top:5px;
    padding:10px;
    border-radius:5px;
    border-color:rgb(65, 65, 213);
    border-style:solid;
    border-width:1px;
}