.separatorBar{
    height:2px;
    width:100%;
    background-color: #dadada;
}

.confirmButton{

    font-size:30px;
}

.optsContainer{
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}