.checkboxCustom {
    > div > span > span > svg {
      height: 50px;
      width: 50px;
    }
  }
  
  .checkboxCustomInner {
    position: relative;
    left: -15px;
  }
  
  .customChecklistComboSelectOptions {

    min-width: 240px;

  }
  
  .checklistsConfirmationContainer {
    position:relative;
    top:-12px;
    border-radius:0px 0px 16px 16px;
    > div {
      display: flex;
      justify-content: center;
    }
  
    background-color: white;
  
    max-height: 660px;
    overflow: hidden;
    z-index:1;
  }
  