.container {
    width: 87vw;
    min-width: 347px;
    max-width: 572px;
    background-color: white;
    height: 13vh;
    position: relative;
      top: -16px;
      border-radius:0px 0px 16px 16px;
  }
  
  .lineBreaker {
    position:absolute;
    width: 100%;
    height: 2px;
    background-color: rgba(88, 86, 86, 0.096);
  }
  
  .buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    border-radius: 0px 0px 16px 16px;
  }
  
  .verifyingStockLabel {
    display: flex;
    justify-content: center;
    margin-top: 2px;
  }
  

  .confirmButtonContainer {
    display: flex;
    justify-content: center;
  }
  
  .confirmationContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius:0px 0px 16px 16px;
    .confirmButton {
      min-height: 80px;
      height: 5vh;
      /*width:60vw;*/
      width: 100%;
      /*max-width:250px;*/
      min-width: 320px;
      font-size: 21px;
      margin: 10px;
    }
    .pauseButton {
      min-height: 80px;
      min-width: 80px;
    }
  }
  