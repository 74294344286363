@use "/src/theme/haxify.scss" as hxf;

.featuresContainer {
  display: flex;
  justify-content: center;
}

.featuresSelection {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width:860px;
  margin-bottom:50px;
  /*display: grid;
  grid-template-columns: repeat(auto-fill, minmax(184px, 1fr));
  column-gap:4.8em;
  row-gap:2em;
  @media all and (max-width: 450px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  @media all and (min-width: 450px) {
    margin-top: 100px;
    width: 90vw;
  }*/
}

.featureBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: 10px;
    width: 100px;
    height: 100px;
  }
  svg:hover {
  }
}

.featureBackgroundContainer:hover {
  color: rgb(77 90 141 / 69%);
}

.featureElement {
  color: hxf.$hxfLightCol3;
  width: 250px;
  height: 120px;
  min-height: 90px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: hxf.$hxfDarkCol1;
  border-radius: 15px 15px 15px 15px;

  margin: 10px;
  position: relative;
  left: -10px;
}

.featureElementTitle{
  color:hxf.$hxfDarkCol1;
}
.featureElement:hover {
  color: hxf.$hxfLightCol1;
  cursor: pointer;
  .featureElementTitle {
    /* display: none; */
    opacity:0.3;
  }

  .featureElementDescription {
    opacity:0.3;
    /*display: none;*/
  }
}

.featureElementDescription {
  color:hxf.$hxfLightColDescriptions;
}


.featureBaseIcon{
  background-color: #2c3757;
}