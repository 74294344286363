.content {
  padding: 5px;
  background-color: white;

  height: 60vh;

  max-height: 660px;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.contentWidthSizes {
  width: 70vw;
  min-width: 353px;
  max-width: 850px;
}

.label {
  text-align: center;
}

.container {
}

.keyPad {
  height: 300px;
}

.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}

.checklistTitle {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 900;
  margin-bottom: 40px;
}

.checklistField {
  /* display:flex;
    justify-content: center;*/

  align-items: center;
  margin-left:10px;
  margin-right:10px;
  margin-bottom: 30px;
  width:100%;
}

.checklistFieldContainer {
  display: flex;
  align-items: center;
  /*justify-content: right;*/
  width:100%;
}

.parameterizedVarLabel {
  font-weight: 500;
  font-size: 25px;
  margin-right: 15px;

  display: inline-block;
  text-align: right;
  width:30%;

}
.parameterizedVarField {
  display: inline-block;
  width:70%;
  max-width:200px;
}
.checkboxCustom {
  > div > span > span > svg {
    height: 50px;
    width: 50px;
  }
}

.checkboxCustomInner {
  position: relative;
  left: -15px;
}

.customChecklistComboSelectOptions {
 
  width: 100%;
  min-width: 160px;
  > div {
    width: 100%;

  }
}
.imageSelectInput{
  
  display:flex;
  justify-content: center;
}
.checklistsConfirmationContainer {
  position:relative;
  top:-16px;
  border-radius:0px 0px 16px 16px;
  > div {
    display: flex;
    justify-content: center;
  }

  background-color: white;

  max-height: 660px;
}

.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}
