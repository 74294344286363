.confirmButton {
}

.confirmButtonContainer {
  display: flex;
  justify-content: center;
}

.confirmationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  
}

.customConfirmationContainer {
    margin: 10px;
    width:100%;
}

.bottomAreaContainer {
  > div {
    display: flex;
    justify-content: center;
  }

  background-color: white;

  width: 75vw;
  min-width: 362px;
  max-height: 662px;
  top: -13px;
  position:relative
}
.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}


.continueBtn{
  display:flex;
  justify-content: center;

}

.divider{
    width: 100%;
      height: 2px;
      background-color: rgba(88, 86, 86, 0.096);
  }