@use "/src/theme/haxify.scss" as hxf;

.container {
}

.content {
  padding: 5px;
  background-color: white;
  width: 60vw;
  @media screen and (max-height: 705px) {
    height: 379px;
  }
  @media screen and (min-height: 705px) {
    height: 579px;
  }
  min-width: 380px;
  max-width: 400px;
  max-height: 215px;
  overflow: auto;


  display:flex;
  justify-content: center;
}

.activeProductionElement {
  margin-top: 20px;
  margin-bottom: 20px;
}
.activeProductionsContainer {
  display: flex;
  justify-content: center;
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: -5px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  width: 250px;
  height: 107px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementTitle {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedActiveProductionElement {
  background-color: rgba(78, 195, 195, 0.123);
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.739);
}

.activeProductionsSection {
  margin-left: 20px;
}
