.pageTitle {
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-top: 20px;
    >div{
        margin:5px;
    }
  }
  .productionOrdersContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .productionOrdersSelection {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

  }
  
  .elemBackgroundContainer {
    display: flex;
    justify-content: center;
  
    width: 100%;
  
    svg {
      position: absolute;
      top: 15px;
      width: 100px;
      height: 100px;
    }
  }
  
  .elemElement {
    color: rgba(61, 61, 61, 0.03);
    min-width: 260px;
    height: 150px;
    max-width: 333px;
    width: 34vw;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.246);
    border-radius: 15px 15px 15px 15px;
    margin: 10px;
  }
  
  .autoLabel{
    font-size:30px;
    display:flex;
    height:100%;
    justify-content: center;
    align-items: center;
    color:black;
  }

  .elemBackgroundBulb{
    background-color: #bea6231c;
    color: rgba(61, 61, 61, 0.03);
    min-width: 260px;
    height: 150px;
    max-width: 333px;
    width: 34vw;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.246);
    border-radius: 15px 15px 15px 15px;
    margin: 10px;
  }
  .elemBackgroundBulb:hover{
    cursor: pointer;
    background-color: #bea72377;
  }
  .productionOrderInfo {
    background-color: var(--ion-color-primary);
    color: white;
    display: flex;
    justify-content: center;
    padding: 6px;
  }

  .scannerDeviceElemTitle{
    margin-top:37px;
    display:flex;
    justify-content: center;
    font-size:20px;
  }
  .elemElement:hover {
    background-color: rgba(78, 195, 195, 0.123);
    cursor: pointer;
    /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
  }
  
  .label{
    color:black;
    font-weight: bold;
  }
  .labelContent{
    color:black;
  }
  
  .poCodeName{
    color: black;
      height: 37px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
  
  .pvContainer{
    color: black;
      height: 37px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
  .goBackLabelContainer {
    display: flex;
    justify-content: center;
  }
  
  .errorArea {
    display: flex;
    justify-content: center;
  }
  
  .pendingActiveProductions {
    background-color: rgba(38, 124, 158, 0.212);
  }
  
  .simultaneousOperations {
    background-color: rgba(38, 187, 142, 0.329);
  }
  
  .btnsContainer {
    margin: 10px;
    padding-top: 50px;
  }
  
  
  .stagedProgressContainer{
    position: absolute;
      width: 86%;
      bottom: 11px;
      color:black;
  }



.confirmationBottomContainer {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 2;
  height: 175px;
  display: flex;
  justify-content: center;
}

.confirmButton {
  min-height: 80px;
  height: 5vh;
  /*width:60vw;*/

  font-size: 20px;
  margin: 10px;
}

.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}

.confirmationBottomContent {
  width: 100%;
}

.centeredElem {
  display: flex;
  justify-content: center;
}

.topTools{
  display:flex;
  justify-content: flex-end;
  margin:20px;
}