.container {
  background-color: white;
  height: 12vh;
  min-height: 100px;
  position:relative;
  

}

.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.verifyingStockLabel {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}

.customConfirmationContainer {
  > :nth-child(1) {
    min-height: 80px;
    height: 5vh;

    /* width: 100%;
        max-width: 180px;
        min-width: 100px;*/
    font-size: 25px;
    margin: 10px;
  }
}
.halfSpace {
}
