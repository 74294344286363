.barContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(51, 49, 49);
    color: white;
    width: 100%;
  }

  .noFullScreen{
    .modal{
      width:unset;
      height:unset;
    }
  }

  .customModal{
    border-radius:16px;
  }
  .bottomContainer{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .overflowScroll{
    overflow-y:scroll;
  }

  .dividerContainer{
    width:100%;
    height:0px;
  }
  .divider{
    height:2px;
    background-color: #e9e9e9;
    width:100%;
  }