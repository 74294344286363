.container {
  width: 100%;
  background-color: white;
  height: 12vh;
  position:relative;
  min-height: 95px;
  top:-16px;
  border-radius:0px 0px 16px 16px;
}

.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.customConfirmationButton {
  min-height: 80px;
  height: 5vh;

  width: 100%;
  max-width: 350px;
  min-width: 175px;
  font-size: 25px;
  margin-bottom: 10px;
}

.verifyingStockLabel {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}
