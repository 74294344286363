.popupPage {
  z-index: 10;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.52); //0.52
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.popupWindow {
  
  z-index: 1001; //20
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.popupWindow > div:first-child > div:first-child {
  border-radius: 16px 16px 16px 16px;
  overflow: hidden;
}


@media screen and (max-height: 918px) {
  .topAdjustment{
    top: 7vh;
  }
}


@media screen and (min-height: 919px) and (max-height: 1020px) {
  .topAdjustment {
    top: 7vh;
  }
}

@media (min-height: 1021px) {
  .topAdjustment {
    top: 7vh;
  }
}


@media screen and (max-height: 918px) {
  .topAdjustmentInner {
    top: 11vh;
  }
}

@media (max-height: 1020px) {
  .topAdjustmentInner {
    top: 11vh;
  }
}

@media (min-height: 1021px) {
  .topAdjustmentInner {
    top: 11vh;
  }
}