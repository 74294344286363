.outDatedAppToolbar {
  background-color: #e6ce99;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.labelsContainer {
  margin-left: 10px;
}
.outDatedUpdateButtonContainer {
  margin: 17px;
}
