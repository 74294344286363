

  .label {
    text-align: center;
  }
  
  .barContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(51, 49, 49);
    color: white;
    width: 100%;
  }
  
  
  .container {
  }
  

  .lineBreaker {
    width: 100%;
    height: 2px;
    background-color: rgba(88, 86, 86, 0.096);
  }
  
  .closeWindowButton {
    padding-right: 5px;
    padding-left: 5px;
  }

  