.pageContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  color: white;
  background: linear-gradient(90deg, black 0%, #0d1718 72%, #0d1213 100%);
  font-size: 20px;
}

.greenColor {
  color: rgb(17, 71, 17);
}

.redColor {
  color: rgb(77, 14, 14);
}

.checkIcon {
  width: 100%;
  display: flex;
  justify-content: center;

  svg {
    width: 30vw;
    height: 30vw;
  }
}

.content {
  width: 100%;
}
