.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.receivingOrdersContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.receivingOrdersSelection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //overflow:auto;
  /*height:100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  grid-gap: 73px;
  @media all and (max-width: 450px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  @media all and (min-width: 450px) {
    margin-top: 17px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }*/
}

.receivingOrderElement {
  margin: 5px;
}
.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: 35px;
    width: 100px;
    height: 100px;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);

  width: 284px;
  height: 195px;

  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
        display:none;
    }

    .elemElementDescription{
        display:none;
    }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
  margin: 10px;
  padding-top: 50px;
}
.settingsContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}

.customSearchBarContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.searchIconStyles {
}

.progressBarContainer {
  position: absolute;
  bottom: 10px;
  width: 250px;
}

.appliedFiltersContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.orderNameCodeContainer {
  color: black;
  align-items: center;
}

.orderNameCode {
  margin-left: 5px;
}

.creationDateContainer {
  position: absolute;
  bottom: 25px;
  color: black;
  font-size:12px;
}
.orderLabel {
  display: flex;
  justify-content: flex-start;
}
.orderTopLabel {
  display: flex;
  justify-content: flex-start;
  font-size:12px;
}

.singleLineOverFlowElipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twoLineOverFlowElipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}