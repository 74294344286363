.container {
}

.content,.subBarInfo {
  padding: 5px;
  background-color: white;
  width: 54vw;
  min-width: 390px;
  max-height: 400px;
  max-width: 400px;
  overflow: auto;
  
}

.content{
  border-radius: 0px 0px 0px 0px;
  border-width: 0px 1px 0px;
  border-style: solid;
}

.subBarInfo {
  background-color: #2c56c0;

  display: flex;
  justify-content: center;
  font-size: 18px;
  color: white;
  div {
    margin-left: 4px;
    margin-right: 4px;

  }
  padding-top: 10px;
  padding-bottom: 10px;
}
.seletedProductInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.mapInputsPrint{
  margin-top:10px;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}

.intPopup {
  > div {
    margin-top: 30px;
  }
}

.keybdContainer {
}


.serialTxt{
  display:flex;
  justify-content: center;
}
.serialCode{
  display:flex;
  justify-content: center;
}