.content {
    padding: 15px;
    background-color: white;
    width: 75vw;
    height: 85px;
    min-width: 380px;
    max-width: 850px;
    max-height: 660px;
    overflow: auto;
}
  
.label {
text-align: center;
}

.container {
    width:100%;
}


.lineBreaker {
width: 100%;
height: 2px;
background-color: rgba(88, 86, 86, 0.096);
}

.popupInfoQuestion {
font-size: 26px;
text-align: center;
}
  