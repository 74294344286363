.content {
    padding: 15px;
    background-color: white;
    width: 75vw;
    height: 130px;
    min-width: 380px;
    max-width: 850px;
    max-height: 660px;
    overflow: auto;
  }
  
  .label {
    text-align: center;
  }
  
  .container {
  }
  

  
  .lineBreaker {
    width: 100%;
    height: 2px;
    background-color: rgba(88, 86, 86, 0.096);
  }
  
  .popupInfoQuestion {
    font-size: 22px;
    text-align: center;
  }
  

  .confirmationButtons{
      background-color: white;
      position:relative;
      top:-16px;
      padding-bottom: 20px;
      border-radius: 0px 0px 16px 16px;
      
  }

  
  .confirmBtnContainer{
    display:flex;
    justify-content: center;
    width:100%;
  }

  .confBtn{
      font-size:20px;
      height:60px;
      width:233px;
  }

