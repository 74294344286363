.selectorLoc {
  margin: 10px;
}
.textModeLocationContainer{
  display:flex;
  justify-content: center;
  align-items: center;
}

.textModeLocationContainer{
  border-radius:10px;
  border-width:1px;
  border-color:rgb(142, 142, 220);
  color:rgb(114, 114, 227);
  border-style:solid;
  padding:5px;
  font-weight: 400;
}

.textModeLocationContainer:hover{
  background-color:rgb(212, 205, 243);
  cursor:pointer;
}