.selectorLoc {
    margin: 10px;
  }
  .lotToolBtn{
    background-color: #ecf9ff;
    display:flex;
    border-radius:5px;
    border-style:solid;
    border-width:1px;
    align-items: center;
    margin:5px;
    padding:5px;
  }
  
  .lotToolBtn:hover{
    cursor:pointer;
    background-color: #c1e9d8;
  }