.container {
    padding-bottom:10px;
}

.content {

  background-color: white;
  

 /* min-width: 362px;*/
  max-height: 662px;

  @media screen and (min-height: 660px) {
    display: flex;
    justify-content: center;
   
  }
  

}

.areaKeybdContainer{
  margin-top:15px;
  margin-bottom:15px;
  @media screen and (min-height: 700px) {

 
   
  }
  
}

.barContainerInfo {
  background-color: rgb(33, 87, 189);
  width: 54vw;
  min-width: 362px;
  max-height: 662px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
  }
}
.barContainerInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}


.confirmationBottomContainer{
    background-color: white;
    position:relative;
    top:-10px;
}

.dividerBottom{
    width:100%;
    height:2px;
    background-color: rgb(212, 212, 212);
}

.inputsContainer{
    margin:16px;
    width:100%;
}

.confirmButton{
    display:flex;
    justify-content: center;
    width:100%;
}

.confirmBtnsContainer{
    display:flex;
    justify-content: center;
    margin:5px;
}