.pageContainer {
  background: linear-gradient(to left, #122230 0%, rgb(9, 17, 24) 100%);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messageContainer {
  background-color: white;
  width: 70vw;
  border-radius: 15px;
}

.messageTitle {
  text-align: center;
  font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
  font-size: 30px;
}

.messageText {
  text-align: center;
}

.buttonRetry {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  button {
    background: linear-gradient(to right, #122230 0%, rgb(9, 17, 24) 100%);
    color: white;
  }
}
