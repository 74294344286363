@use "/src/theme/haxify.scss" as hxf;

.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.buyOrdersContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.buyOrdersSelection {
  //overflow:auto;
  height: 100%;
  /*border-style: solid;
      border-width:1px;
      border-color:black;
      border-radius: 15px 15px 15px 15px;*/
  /* max-width:90vw;
     
      justify-content: flex-start;
      flex-wrap: wrap;*/
      display:flex;
      justify-content: center;
      /*
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(184px, 1fr));
  grid-gap: 53px;*/
  @media all and (max-width: 450px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  @media all and (min-width: 450px) {
    margin-top: 17px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
  /*background-image:
      repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
      background-size: 71px 71px;*/
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: 16px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  min-width: 234px;
  height: 150px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
  margin: 10px;
  padding-top: 50px;
}
.settingsContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}

.customSearchBarContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.searchIconStyles {
}

.orderNameCodeContainer {

  color: black;

}

.orderNameCode {
  margin-left: 5px;
}

.creationDateContainer {
  color: black;
  font-size:12px;
  position:absolute;
  bottom:23px;
}

.orderTopLabel{
  font-size:12px;
}
.orderLabel {
  width: 160px;
}
.progressBarContainer {
  position: absolute;
  bottom: 10px;
  width: 200px;
}

.singleLineOverFlowElipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twoLineOverFlowElipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}