.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.optionsContainer {
  position: absolute;
  top: 37%;
  transform: translateY(-37%);
  width: 100%;
}

.optionsContent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;

  svg {
    position: absolute;
    top: 0px;
    color: #f1f1f1db;
    width: 100px;
    height: 100px;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  min-width: 190px;
  height: 100px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;

  margin: 10px;
}

.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
  margin: 10px;
  padding-top: 50px;
}





.shippingsBtn{
  background-color:#f5ffff;
}

.shippingsBtn:hover{
  background-color:#cdf8f8;
}


.salesBtn{
  background-color:#f3fffa;
}

.salesBtn:hover{
  background-color:#d3f4e6;
}

.featureSellIcon{
  background-color:#5c956e;
}

.featureShipIcon{
  background-color:#5c7895;
}