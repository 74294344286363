.elemBackgroundContainer {
    display: flex;
    justify-content: center;
  
    width: 100%;
  
  
    svg {
      position: relative;
      top: -7px;
      right: 0px;
      width: 100px;
      height: 80px;
    }
  }
  
  .elemElement {
    color: rgba(61, 61, 61, 0.15);
    min-width: 175px;
    max-width: 315px;
    height: 100px;
    //width:20vw;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.246);
    border-radius: 15px 15px 15px 15px;
  
  }
  
  .selectedActiveProductionElement {
    background-color: rgba(78, 195, 195, 0.123);
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.739);
  }
  
  .activeProductionElement {
    margin:5px;
  }
  
  .elemElement:hover {
    background-color: rgba(78, 195, 195, 0.123);
    cursor: pointer;
    /*.elemElementTitle{
            display:none;
        }
    
        .elemElementDescription{
            display:none;
        }*/
  }
  