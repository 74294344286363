.popupPage {
    z-index: 10;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.52);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  .popupWindow {
    
    z-index: 20;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  
  .popupWindow > div:first-child > div:first-child {
    border-radius: 16px 16px 16px 16px;
    overflow: hidden;
  }
  
  @media (max-height: 918px) {
    .popupWindow {
      top: 2vh;
    }
  }
  
  @media (max-height: 1020px) {
    .popupWindow {
      top: 8vh;
    }
  }
  
  @media (min-height: 1021px) {
    .popupWindow {
      top: 10vh;
    }
  }
  