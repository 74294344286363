.content {
    padding: 5px;
    background-color: white;

    min-height: 340px;
    height:53vh;
    max-width: 850px;
    max-height: 540px;
    overflow: auto;
  }

  .askIfAssignedWork{
    font-size:24px;
    text-align: center;
  }

  .checkboxCustomInner{
    display:flex;
    align-items: center;
    margin-left:10px;
  }

  .pvCheckLabel{
    margin-left:10px;
  }

  .inputContainer{
    margin-top:15px;
  }

  .nameCl{
    font-size:25px;
    color:gray;
    display:flex;
    justify-content: center;
  }

  .container {
    width: 100%;
    background-color: white;
    height: 12vh;
    position:relative;
    top: -15px;
    border-radius: 0px 0px 16px 16px;
    min-height:120px;
    z-index: 1;
  }
  
  .buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .confirmBtnContainer{
    font-size:30px;
    height:100px;
  }
  
  .lineBreaker {
    width: 100%;
    height: 2px;
    background-color: rgba(88, 86, 86, 0.096);
  }

  .confirmButton{
    font-size:30px;
    height:100px;
  }
  
  .confirmationContainer{
  margin-top:10px;
  }