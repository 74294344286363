@use "/src/theme/haxify.scss" as hxf;


.content {
  padding: 5px;
  background-color: white;


  max-width: 410px;
  max-height: 642px;
  overflow: auto;
}

.activeProductionElement {
  margin-top: 20px;
  margin-bottom: 20px;
}
.activeProductionsContainer {
  display: flex;
  justify-content: center;
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
  height:40px;
}

.btnCancel{
  display:flex;
  justify-content: center;
  ion-button{
    height:100px;
  }
}

.orSeparate{
  font-size:50px;
  display:flex;
  justify-content: center;
  margin:20px;
}