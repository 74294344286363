.content {
    padding: 5px;
    background-color: white;
    width: 60vw;
    height: 66vh;
    min-width: 380px;
    max-width: 400px;
    max-height: 662px;
    overflow:auto;
  }

  .stepIcon{
    width:100%;
    display:flex;
    justify-content: center;
  }


  .stepRemains{
    background-color: rgb(28, 74, 0);
    color:white;
    border-radius:100%;
    width:40px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .stepTrash{
    color:white;
    background-color: #b1892b;
    border-radius:100%;
    width:40px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .stepIconResultingProducts{
    
    color:white;
    background-color: rgb(115, 215, 142);;
    border-radius:100%;
    width:40px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .stepIconFirst{
     color:white;
     background-color: rgb(115, 115, 215);
     border-radius:100%;
     width:40px;
     height:40px;
     display:flex;
     justify-content: center;
     align-items: center;
  }
  
  
  .label {
    text-align: center;
    font-size:26px;
  }
  
  .productionTgtStock {
    div {
      display: flex;
      justify-content: center;
      margin: 2px;
    }
  }
  
  .lotDivContainer {
    display: block !important;
  }
  .container {
  }
  
  .keyPad {
    height: 300px;
  }
  

  .adjProductContainer{
    margin:10px;
    display:flex;
    justify-content: center;
    border-style:solid;
    border-width:1px;
    border-radius:16px;
    border-color:rgb(115, 115, 215);
  }

  .remainsProductContainer{
    margin:10px;
    display:flex;
    justify-content: center;
    border-style:solid;
    border-width:2px;
    border-radius:16px;
    border-color:rgb(28, 74, 0);
  }

  .remainsCloseBtn{
    cursor: pointer;
    position: absolute;
    right: -2px;
    background-color: #b52929;
    width: 39px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8;
    top: -1px;
    border-radius: 0px 15px 0px 0px;
    font-weight: 900;
  }

  .remainsContainerTop{
    display:flex;
    justify-content: center;
    width:100%;
    background-color: rgb(28, 74, 0);
    color:white;
    border-radius:16px 16px 0px 0px;
    position:relative;
    top:-1px;
    font-size:18px;
    padding-top:5px;
    padding-bottom:5px;
  }

  .adjContainerTop{
    display:flex;
    justify-content: center;
    width:100%;
    background-color: rgb(115, 115, 215);
    color:white;
    border-radius:16px 16px 0px 0px;
    position:relative;
    top:-1px;
    font-size:18px;
    padding-top:5px;
    padding-bottom:5px;
  }

  .transfProductContainer{
    margin:10px;
    display:flex;
    justify-content: center;
    border-style:solid;
    border-width:1px;
    border-radius:16px;
    border-color:rgb(115, 215, 157);
  }

  .irregularTransfProductContainer{
    margin:10px;
    display:flex;
    justify-content: center;
    border-style:solid;
    border-width:1px;
    border-radius:16px;
    border-color:rgb(177, 137, 43);
  }
  .irrContainerTop{
    display:flex;
    justify-content: center;
    width:100%;
    background-color: rgb(177, 137, 43);
    color:white;
    border-radius:16px 16px 0px 0px;
    position:relative;
    top:-1px;
    font-size:18px;
    padding-top:5px;
    padding-bottom:5px;
  }
  .transfContainerTop{
    display:flex;
    justify-content: center;
    width:100%;
    background-color: rgb(115, 215, 142);
    color:white;
    border-radius:16px 16px 0px 0px;
    position:relative;
    top:-1px;
    font-size:18px;
    padding-top:5px;
    padding-bottom:5px;
  }


  .inputContainerQtyWorked{
    display:flex;
    justify-content: center;
    margin-bottom:5px;
  }

  .addrembtn{
    height:55px;
    position:relative;
    top:-4px;
  }

  .remainsBtnStyle{
    button{
      background-color: red;
    }

  }