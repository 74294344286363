.container {
  top: -15px;
  position: relative;
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;

  width: 100%;
  background-color: white;
}

.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.confirmButtonContainer {
  display: flex;
  justify-content: center;
}

.confirmationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  .confirmButton {
    min-height: 80px;
    height: 5vh;
    width: 60vw;
    max-width: 250px;
    min-width: 175px;
    font-size: 25px;
    margin: 10px;
  }
  .pauseButton {
    min-height: 80px;
    min-width: 80px;
  }
}
