.content {
    padding: 5px;
    background-color: white;
    width: 75vw;
    min-height: 340px;
    height:50vh;
   
    max-width: 850px;
    max-height: 540px;
    overflow: auto;
  }

  @media only screen and (min-width: 600px) {
    .content{
      min-width: 600px;
    }
    
  }

  @media only screen and (max-width: 525px) {
    .content{
      min-width: 380px;
    }
    
  }

  .pvsContainer{
    margin:5px;
    font-size:11px;
    color:#7d7dc5;
  }

  .transformationsContainer{
    margin:5px;
    font-size:11px;
    color:#7d7dc5;
    border-style:solid;
    border-width:1px;
    border-radius:10px;
  }

  .adjProductContainer{
    margin:10px;
    border-radius: 10px;
    border-width:1px;
    border-style:solid;
    border-color:#5e5783;
    padding:10px;
  }

  .adjProductInputContainer{
    display:flex;
    align-items: center;
  }

  .editAdjustmentBtn{
    border-radius: 10px;
    border-width:1px;
    border-style:solid;
    border-color:#24176b;
    background-color: #f4f3f5;
    margin:10px;
    cursor:pointer;
  }

  .hasErrorAdjProductContainer{
    background-color: #fff4f4;
    border-color:red;
  }