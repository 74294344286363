.locTitle{
    font-size:13px;
    font-weight:600;
}

.qtyVal{
    font-size:18px;
    font-weight:600;
}

.smTypeLabel{
    font-size:13px;
}

.revertedMovementLabel{
    font-size:13px;
    color:white;
    font-weight:600;
}

.revertedMovementRow{
    background-color: gray;
}