$text-colour: #262626;

$btn-bg-icons-colour:#bdbdbd2e;

.popup-content {
  width: 90vw;
  max-width: 1100px;
  padding: 0px;
  border: 0px;
}

.popup-content-border-styles {
  border-radius: 0px 0px 10px 10px;
  border: solid;
  border-width: 1px;
}

.popup-barcontainer-border-styles {
  border-radius: 10px 10px 0px 0px;
}

.popup-content-border-sides-only-styles {
  border: solid;
  border-width: 0px 1px 0px 1px;
}

.popup-bottom-confirmation-border-styles {
  border-radius: 0px 0px 10px 10px;
  border-width: 0px 1px 1px;
  border-style: solid;
}


ion-content{
  --offset-top:0px !important;
  --offset-bottom: 0px !important;
}

$hxfDarkCol1:#2d3658;
$hxfLightCol1:rgb(77 90 141 / 69%);
$hxfLightCol2:rgb(77 90 141 / 13%);
$hxfLightCol3:rgb(165 171 193 / 13%);
$hxfLightColDescriptions:rgb(132 138 164);
/*:export {
  hxfBorderOutlineColor: $hxfBorderOutlineColor; 
}*/

.hxfAlertToast{
  color:white;
}

.hxfError{
  color:#d14a21;
}

.hxfBorderErrorColor{
        border-color:#d14a21;
}


::-webkit-scrollbar {
  width: 7px;
  height:7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

.hxf-modal {
 /* --backdrop-opacity: 1; */
  /* backdrop-filter: blur(3px); */

  &::part(backdrop) {
    /*  background: #000;*/
  }

  &::part(handle) {
       /* background: var(--ion-color-primary); */ 
      width: 150px;
      height: 6px;
  }

  &::part(content) {
      border-radius: 10px; // Here the border radius
     /* box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);*/
  }
}


ion-content{
  --keyboard-offset: 0 !important;
}



.hxfFeatureCornerIconBtn{
  display: flex;
  justify-content: center;

  width: 100%;
  color:white;

  .hxfFeatureCornerIconContainer{
    position: absolute;
    top: -7px;
    border-radius: 14px;
    left: -16px;
    width: 33px;
    height: 34px;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  svg{

  }

  
}