@use "/src/theme/haxify.scss" as hxf;

.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 25px;
  margin-top: 20px;
}
.optionsContainer {
  position: relative;
  top: 30px;
  width: 100%;
}

.optionsContent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: 20px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  min-width: 220px;
  height: 140px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;

  margin: 10px;
  width:250px;
  overflow: hidden;

  display:flex;
  align-items: center;
  justify-content: center;
}

.elemElementTitle{
  font-size: 19px;
  font-weight: 900;
  width:100%;
 
 text-align: center;

  text-overflow: ellipsis;

  position:relative;


}

.elemElement:hover {
  /*background-color: rgba(78, 195, 195, 0.123);*/
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.statusContainer{
    display:flex;
    align-items: center;
    color:black;
    position: absolute;
    bottom: 3px;
    left: -6px;
    justify-content: center;
    width:100%;
}

.actionIcon{
  display:flex;
  width: 100%;
  color:rgb(255, 255, 255);
  justify-content: center;
  
}

.actionIcon >svg{
  height:50px;
  width:50px;
}

.unavElem{
  background-color: rgb(248, 227, 227);
  color:rgb(0, 0, 0);
}


.unavElem:hover{
  background-color: rgb(242, 161, 156);
}



.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;
  position:relative;
  top:-60px;
  left:-10px;

  svg {
    position: absolute;

    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}


.elemBackgroundContainer:hover {


  svg {

    color:'white';
  }
}

.topInfoContainer{
  display:flex;
  justify-content: center;
}