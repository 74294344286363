.content {
    padding: 5px;
    background-color: white;
    width: 75vw;
    min-height: 340px;
    height:34vh;
    min-width: 380px;
    max-width: 850px;
    max-height: 540px;
    overflow: auto;
  }

  .askIfAssignedWork{
    font-size:24px;
    text-align: center;
  }