.errorDivContainer {
  display: flex;
  justify-content: center;
}

.errorDivElement {
  color: rgba(61, 61, 61, 0.15);
  width: 70vw;
  min-height: 90px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: rgba(61, 61, 61, 0.411);
}

.featureElement:hover {
  color: rgba(61, 61, 61, 0.993);
  cursor: pointer;
  .featureElementTitle {
    display: none;
  }

  .featureElementDescription {
    display: none;
  }
}

.title {
  color: black;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.description {
  color: black;
  font-size: 16px;
  margin-top:10px;
}

.divTitleIcon {
  color: rgb(92, 74, 42);
}

.secondDescription {
  color: black;
  font-size: 16px;
  margin-top: 10px;
}

.errorDivInner {
  margin-top: 20vh;
}

.entityGoBack {
  display: flex;
  align-items: center;
  cursor: pointer;
}
