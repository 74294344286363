.pageContainer {
  display: flex;
  justify-content: center;
}
.keyboardLines {
}
.digitKeyboardContainer {
  border-radius: 5px;
  /* height: 78vw; */
  max-width: 328px;
  min-width: 254px;
  
  max-height: 775px;
  aspect-ratio: 1/1.5;

}
.keyboardInput {
  display: flex;
  justify-content: flex-end;
  margin-left: 3px;
  margin-right: 3px;
  input:focus::placeholder {
    color: transparent;
  }
  input {
    width: 100%;
    height: 50px;
    /*max-width: 206px;*/
    @media screen and (min-height: 750px) { //screens with more height
      max-width: 206px;
      width: 39.5vw;
    }
    @media screen and (max-height: 749px) { //screens with less height
      max-width: 180px;
    }
    min-width: 156px;
    text-align: center;
    border-radius: 5px;
    border: 0px solid;
    /*padding-right:50px;*/
    padding-left: 10px;
  }
  .uomLabel {
    display: block;
    position: relative;
    color: gray;
  }
}

.inputError {
  input {
    border: 2px solid red;
  }
}

.keyNoSelection {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
      Introduced in Internet Explorer 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
  -ms-user-select: none;
  user-select: none;
}
.digitKeyboardContainer {
  /*margin-top: 10px;*/

  background-color: rgb(65, 120, 172);
  .keyboardLineContainer {
    display: flex;

    @media screen and (max-height: 750px) { //screens with less height
      justify-content: center;
    }
    @media screen and (min-height: 750px) { //screens with more height
      justify-content: flex-start;
    }
    
    margin: 5px;

    .keyboardKey {
      color: white;
      margin: 0px 3px 3px 3px;
      background-color: rgb(29, 133, 213);
      @media screen and (max-height: 750px) { //screens with less height
        max-width:80px;
      }
      @media screen and (min-height: 750px) { //screens with more height
        max-width: 100px;
      }
     
      min-width: 75px;
      width: 23vw;
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 15px;
      font-size: 30px;
    }
    .keyInputDel {
      display: flex;
      justify-content: center;
      align-items: center;
      width:100%;
    }
    .keyboardDeleteKey {
      border-radius: 5px;
      color: white;
      margin: 0px 0px 0px 3px;
      background-color: rgb(29, 133, 213);
      /*max-width: 100px;*/
      min-width: 75px;
      /*width: 27vw;*/
      height: 50px;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .keyboardDeleteKey:hover {
      background-color: rgba(29, 133, 213, 0.534);
    }
    .twoSlotsKeyboardKey {
      border-radius: 15px;
      color: white;
      margin: 0px 3px 3px 3px;
      background-color: rgb(29, 133, 213);


      @media screen and (min-height: 750px) { //screens with more height
       
        position: relative;
        max-width: 206px;
        min-width: 156px;
        width: 100%;
       
      }
      @media screen and (max-height: 750px) { //screens with less height
        width: 166px;
        
      }
      aspect-ratio: 1 / 0.47;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .twoSlotsKeyboardKey:hover {
      background-color: rgba(29, 133, 213, 0.534);
    }
    .keyboardKey:hover {
      background-color: rgba(29, 133, 213, 0.534);
    }
  }
}

.confirmButton {
  border-color: rgba(17, 19, 18, 0.205);
  border-style: solid;
}

.innerBtnLabel {
  div {
    width: 100%;
    height: 100%;
  }
}

.disabledButton {
  background-color: grey !important;
  cursor: default !important;
}

.uomLabelField {
  position: absolute;
  z-index: 1;
  left: -48px;
  top: 14px;
  width:48px;
}

.subPlaceholderContainer {
  position: absolute;
}

.subPlaceholder {
  position: relative;
  top: 32px;
  right: 6px;
  font-size: 12px;
  color: gray;
}
