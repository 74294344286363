.content {
    padding: 5px;
    background-color: white;
    width: 58vw;
    height: 60vh;
    min-width: 326px;
    max-width: 575px;
    max-height: 660px;
    overflow: auto;
  }
  .contentIrregularitysSelect {
    padding: 5px;
    background-color: white;
    width: 75vw;
    height: 50vh;
    min-height: 330px;
    min-width: 380px;
    max-width: 850px;
    max-height: 520px;
    overflow: auto;
  }
  .label {
    text-align: center;
  }
  
  .productionTgtStock {
    div {
      display: flex;
      justify-content: center;
      margin: 2px;
    }
  }
  
  .container {
  }
  
  .keyPad {
    height: 300px;
  }
  
  .selectionContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom:100px;
    @media only screen and (max-width: 975px) {
      justify-content: center;
    }
  }
  
  .lineBreaker {
    width: 100%;
    height: 2px;
    background-color: rgba(88, 86, 86, 0.096);
  }
  
  .addedIrregularitys {
    display: flex;
    background-color: white;
    overflow: auto;
    width: 75vw;
    max-width: 850px;
    min-width: 380px;
  }
  .addedIrregularity {
    margin: 5px;
    border-radius: 5px;
    background-color: var(--ion-color-primary);
    display: flex;
    align-items: center;
  }
  
  .IrregularityName {
    margin: 10px;
    color: white;
  }
  
  .IrregularityRemoveBtnContainer {
    margin: 10px;
  }
  
  .IrregularityRemoveBtn {
    width: 50px;
  }
  
  .machineTip{
    width: 100%;
    background-color: #ebeac8;
    display:flex;
    justify-content: center;
  }
  
  .machineContainer{
    margin-top:4px;
    margin-bottom:4px;
  }