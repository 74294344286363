@use "/src/theme/haxify.scss" as hxf;
.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: 20px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  width: 250px;
  height: 195px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.elemElementDescription {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
}
.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}
.spaceArrow {
  margin-left: 20px;
}

.elemElementTitle {
  /* white-space: nowrap;
    width: 100%;
    overflow: hidden;       
    text-overflow:    ellipsis;*/
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  -moz-line-clamp: 3;
  -ms-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  height: 25px;
}

.labelsProductCap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
}

.selectedActiveProductionElement {
  background-color: rgba(78, 195, 195, 0.123);
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.739);
}

.operationsSelectionSection {
  margin-left: 20px;
  display: flex;
}

.content {
  padding: 5px;
  background-color: white;
  width: 95vw;
  @media screen and (max-height: 764px) {
    min-height: 259px;
    height: 55vh;
  }
  @media screen and (min-height: 765px) {
    min-height: 312px;
    height: 60vh;
  }
  min-width: 380px;
  max-height: 820px;

  overflow: auto;
}

.activeProductionElement {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.operationsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.contentTitle {
  display: flex;
  justify-content: center;
  font-size: 23px;
  margin-top: 10px;
}
.container {
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.selectedProductionOrderInfo {
  background-color: rgb(21, 92, 223);
  display: flex;
  justify-content: center;
  font-size: 26px;
  color: white;
  div {
    margin: 4px;
  }
}
.selectedOperationInfo {
  background-color: rgb(33, 87, 189);
  display: flex;
  justify-content: center;
  font-size: 26px;
  color: white;
  div {
    margin: 4px;
  }
}

.addAllLabel {
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: black;
}

.ppvvsContainer {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tskPrgBar {
  position: relative;
  bottom: -25px;
}

.tskPgrInfo {
  color: black;
  font-size: 10px;



  display: flex;
  justify-content: center;
  position:relative;
  top:2px;
}

.tskBarReposition{

}

.gobackElemCont{


}
.machCategoryContainer{
  display:flex;
 /* justify-content: center;*/
}

.machbox{
  /* background-color:#8b8bc7; */
  border-radius:10px;
  display:flex;
  justify-content: center;
  align-items: center;
  color:white;
  margin:2px;
  font-weight: 600;
  font-size: 12px;
  height: 25px;
  /*overflow: hidden;*/
}

.machboxesContainer{
  display:flex;
  justify-content: center;
  /*overflow:auto;

  width: 248px;
  position: relative;
  left: -16px;*/
}

.machCategoriesContainer{
  background-color: #eee5f3;
  height:65px;
  width:250px;
  overflow:auto;
}

.machinfoContainer{
  color:black;
  text-align:left;
  position:absolute;
  top:95px;
  width:218px;
  font-size:13px;
}
