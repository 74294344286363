.toolbarLangSwitcher{
    display:flex;
    align-items: center;
}

.langLabel{
    margin:5px;
}

.iconLeftContainer{
    margin-right:5px;
}