.confirmButton {
}

.confirmButtonContainer {
  display: flex;
  justify-content: center;
}

.confirmationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  .confirmButton {
    min-height: 80px;
    height: 5vh;
    width: 60vw;
    max-width: 250px;
    min-width: 175px;
    font-size: 25px;
    margin: 30px;
  }
  .pauseButton {
    min-height: 80px;
    min-width: 80px;
  }
}

.customConfirmationContainer {
    margin: 10px;
}

.bottomAreaContainer {
  > div {
    display: flex;
    justify-content: center;
  }

  background-color: white;

  width: 54vw;
  min-width: 350px;
  max-height: 400px;
  max-width: 400px;
  top: -16px;
  position:relative
}
.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}


.continueBtn{
  display:flex;
  justify-content: center;

}