.selectorElem {
  margin: 13px 0px 0px 0px;
}

.label {
  text-align: center;
}

.productVariablesSection {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.pvContainer {
  margin-top: 10px;
}

.tableArea {
  display: flex;
  justify-content: center;

  @media (max-width: 685px) {
    //width:600px;
  }

  @media (min-width: 686px) {
    width: 100%;
  }
}
/*
        background-color: var(--ion-color-primary);  
        color: var(--ion-color-primary-contrast);
        */
.consumptionsTableContainer {
  /*width: 100%;*/

  min-width: 300px;
  display: table;
  .consumptionsTableBody {
    display: table-row-group;
  }
  .consumptionsRow {
    display: table-row;
  }

  .consumptionsLeftHeader {
    border-radius: 5px 0px 0px 0px;
  }
  .consumptionsRightHeader {
    border-radius: 0px 5px 0px 0px;
  }
  .consumptionsBodyCellHeader {
    display: table-cell;
    border: 1px solid #503528;

    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    background-color: #503528;
    color: var(--ion-color-primary-contrast);
  }
  .consumptionsBodyCell {
    display: table-cell;
    border: 1px solid #503528;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
  }
}

.consumptionProductName {
  font-size: 20px;
  margin: 5px;
}

.consumptionVarsContainer {
  margin: 5px;
}

.consumptionVarsTitle {
  font-weight: 600;
}

.consumptionsQtyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 100px;
  .consumptionsQty {
    font-size: 40px;
  }
}

.popupBottomFixedDiv {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.qtyFieldContainer {
  input {
    width: 100px;
    font-size: 20px;
    text-align: center;
  }
}

.consumptionsProductCol {
  max-width: 200px;
}

.consumptionsParameterizedVariablesLabel {
  font-size: 12px;
}
.consumptionsParameterizedVariablesAndValues {
  font-size: 12px;
}

.notEnoughtAvailableQtyLabel {
  color: var(--ion-color-danger);
}


.lotToolsContainer{
  display:flex;
  justify-content: center;
}
