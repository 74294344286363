.ripplesOverride {

  div {
    border-radius: 15px;
  }
}

.ripplesOverride2 {
  div {
    border-radius: 5px;
  }
}

.ripplesOverride3 {
  div {
    border-radius: 5px;
  }
}


.ripplesOverride4{
  
  @media screen and (min-height: 750px) { //screens with more height
    width:100%;
  }
  div {
    @media screen and (min-height: 750px) { //screens with more height
      width:100%;
    }
    border-radius: 5px;
  }
}