
  .transfProductContainer{
    margin:10px;
    display:flex;
    justify-content: center;
    border-style:solid;
    border-width:1px;
    border-radius:16px;
    border-color:rgb(115, 215, 157);
  }

  .irregularTransfProductContainer{
    margin:10px;
    display:flex;
    justify-content: center;
    border-style:solid;
    border-width:1px;
    border-radius:16px;
    border-color:rgb(177, 137, 43);
  }
  .irrContainerTop{
    display:flex;
    justify-content: center;
    width:100%;
    background-color: rgb(177, 137, 43);
    color:white;
    border-radius:16px 16px 0px 0px;
    position:relative;
    top:-1px;
    font-size:18px;
    padding-top:5px;
    padding-bottom:5px;
  }
  .transfContainerTop{
    display:flex;
    justify-content: center;
    width:100%;
    background-color: rgb(115, 215, 142);
    color:white;
    border-radius:16px 16px 0px 0px;
    position:relative;
    top:-1px;
    font-size:18px;
    padding-top:5px;
    padding-bottom:5px;
  }


  .inputContainerQtyWorked{
    display:flex;
    justify-content: center;
    margin-bottom:5px;
  }

  .addrembtn{
    height:55px;
    position:relative;
    top:-4px;
  }

  .remainsBtnStyle{
    button{
      background-color: red;
    }

  }