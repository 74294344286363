.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
}
.productionOrdersContainer {
  justify-content: center;
}
.poBtns{
  display:flex;
  justify-content: flex-end;
  margin-right:10px;
  margin-left:10px;
}
.productionOrdersSelection {
  /*border-style: solid;
    border-width:1px;
    border-color:black;
    border-radius: 15px 15px 15px 15px;*/
  /* max-width:90vw;
    display:flex;
    justify-content: flex-start;
    flex-wrap: wrap;*/
  /*display: grid;
  grid-template-columns: repeat(auto-fill, minmax(184px, 1fr));
  grid-gap: 2.5em;
  @media all and (max-width: 450px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  @media all and (min-width: 450px) {
    margin-top: 50px;
    width: 90vw;
  }*/
  /*background-image:
    repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
    repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
    background-size: 71px 71px;*/
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: 25px;
    width: 100px;
    height: 100px;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.066);
  min-width: 250px;
  max-width:250px;
  height: 175px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: #2d3658;
  border-radius: 15px 15px 15px 15px;
  margin: 1%;
  overflow: hidden;
}

.productDataContent{
  position:relative;
  height:130px;


}

.operationDescriptionContainer{
  text-align:center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
}

.elemElementTitle {
  color: black;
  overflow: hidden;
}

.elemElementDescription {
  color: black;

  text-align: center;
  font-size: 20px;
  border: solid;
  border-width: 1px;
  border-radius: 25px;
  width: 155px;
  height: 30px;
}

.progressBarContainer {
  position: absolute;
  bottom: 10px;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}
.fillerBar {
  overflow: hidden;
  position: relative;
  background-color: rgb(43 37 141 / 25%);
  /*width:50%;*/
  height: 29px;
  left: -1px;
  z-index: -1;
  border-width: 1px;
  border-radius: 25px;
}

.repositionDoneProduct{
  position: relative;
    top: 13px;
    left: -27px;
}
.repositionDoneWork{
  position:relative;
  height:33px;
  left: -27px;
}
.fixedVarsContainer {
  color: black;
  font-size: 10px;

  max-width: 180px;
  height: 55px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fixVarLab {
  display: inline;
}
.fixVarVal {
  margin-right: 5px;
  display: inline;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.ppvContainer {
  color: rgba(0, 0, 0, 0.671);
  font-size: 12px;
}
.pvsContent {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}


.infoContainer{
  color:black;
  text-align:center;
  font-size:15px;
}

.adjMachineContainer{
  white-space: nowrap;
}


.machbox{
  /* background-color:#8b8bc7; */
  border-radius:10px;
  display:flex;
  justify-content: center;
  align-items: center;
  color:white;
  margin:2px;
  font-weight: 600;
  font-size: 12px;
  height: 16px;
  /*overflow: hidden;*/
}

.machboxesContainer{
  display:flex;
  justify-content: center;
  /*overflow:auto;

  width: 248px;
  position: relative;
  left: -16px;*/
}


.machCategoryContainer{
  display:flex;
 /* justify-content: center;*/
}

.machCategoriesContainer{
  background-color: #eee5f3;
  height:40px;
  width:250px;
  overflow:auto;
}

.machinfoContainer{
  color:black;
  text-align:left;
  position:relative;
  
  font-size:13px;
}


.bomSelProductContainer{
  color:black;
  font-size:13px;
  width:190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

