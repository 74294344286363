@use "/src/theme/haxify.scss" as hxf;

.container {
}

.content {
 
  background-color: white;
  width: 87vw;
  min-width: 347px;
  max-width: 572px;
  max-height: 490px;
  overflow: auto;
}

.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.subcontractsContainer{
  display:flex;
  justify-content: center;
  flex-wrap:wrap;
  margin-bottom:25px;
  margin-top:25px;
  
}
.subcontractContainer{
  margin:5px;
  border-radius: 16px;
  border-style: solid;
  border-color:rgb(143, 143, 143);
  border-width:1px;
  height:80px;
  width:225px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.subcontractContainer:hover{
  cursor: pointer;
  background-color: rgb(227, 224, 240);
}

.selectedSubcontract{
  border-color: black;
  background-color: rgb(174, 170, 191);
  border-width:3px;
}

.scantip {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin: 20px;
}

.externalSourceTitle{
  display:flex;
  justify-content: center;
  margin-top:10px;
  
}