.topPageContainer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  align-items: center;
}

@media only screen and (max-width: 525px) {
  .topPageContainer {
    display: flex;
    justify-content: flex-start;
    padding-top: 20px;
    align-items: center;
    padding-left:45px;
  }
  
}

@media only screen and (max-width: 350px) {
  .topPageContainer {
    display: flex;
    justify-content: flex-start;
    padding-top: 20px;
    align-items: center;
    padding-left:25px;
  }
  
}

.widthAndCenter{
  width:100%;
  display:flex;
  justify-content: center;
}

.viewFilesBtn{
  margin-bottom:6px;
  margin-left:12px;
  margin-right:12px;
  width:100%;
}

.viewFilesTitle{
  font-size:18px;
  font-weight:600;
}

.pageTitle {
  font-size: 20px;
}

.cancelButton {
  margin-right: 20px;
  ion-button {
    width: 100%;
  }
}

.homeButton {
  margin-right: 20px;
  ion-button {
    width: 100%;
  }
}

.cornerButtons {
  display: flex;
}
.productionStartPreviewContainer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
}

.elemElement {
  /*width: 80vw;*/
  min-height: 100px;
  max-width: 560px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left:10px;
}

.elemTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.246);
}

.operationTakingTime {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.popDescriptionTitle{
  display:flex;
  justify-content: center;
}
.operationDescription {
  display: flex;
  justify-content: center;
  margin:10px;

}
.infoSection{
  margin:18px;
}
.overlayContainer {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.37);
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 1;
}

.pdeReminderDataInfo{
  margin-top:10px;
  background-color: #f9d99f;
}