@use "/src/theme/haxify.scss" as hxf;


.content {
  padding: 5px;
  background-color: white;
  width: 60vw;
  height: 57vh;
  min-width: 350px;
  max-width: 600px;
  max-height: 642px;
  overflow: auto;
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}

.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.adjustedToProductContainer{
   
    color:#7d7dc5;
}
.pvsContainer{
    margin:5px;
    font-size:11px;
    color:#7d7dc5;
  }

  .transformationsContainer{
    margin:5px;
    font-size:11px;
    color:#7d7dc5;
    border-style:solid;
    border-width:1px;
    border-radius:10px;
  }

  .machTopInfo {
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    padding: 2px;
    font-size:23px;
  }
  