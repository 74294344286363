.container {

}

.content {

  background-color: white;
  
  width: 54vw;
  min-width: 362px;
  max-height: 662px;
  overflow: auto;

  @media screen and (min-height: 660px) {
    display: flex;
    justify-content: center;
   
  }
  

}

.areaKeybdContainer{
  margin-top:15px;
  margin-bottom:15px;
  @media screen and (min-height: 700px) {

 
   
  }
  
}

.selectedProductInfo {
  background-color: rgb(33, 87, 189);
  width: 54vw;
  min-width: 362px;
  max-height: 662px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
  }
}
.lineProductInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:center;
}

@media screen and (min-height: 850px){

  .lineProductInfoLabels{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: wrap;
  }
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}

.intPopup {
  > div {
   /*  margin-top: 30px; */
  }
}

.keybdContainer {
}
