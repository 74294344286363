.dangerButton {
  background-color: var(--ion-color-danger);
}

.primaryButton {
  background-color: var(--ion-color-primary);
}
.successButton {
  background-color: var(--ion-color-success);
}
.lockButton {
  background-color: gray;
}

.btnDisabled:hover{
  cursor:not-allowed;
  
}

.btnDisabled{
  div:first-child{
    background-color: rgba(100, 100, 100, 0.166);
  }
 
}

.buttonContainer {
  z-index: 1;
  margin: 5px;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  cursor: pointer;

  .buttonContent {
    padding-left: 8px;
    color: white;
    border-radius: 3px;

    display: flex;
    align-items: center;
    height: 40px;
    padding-right: 10px;
  }
}

.ripplesContainer {
  width: 100%;
}

.buttonLabelContainer {
  font-size: 15px;
  letter-spacing: 0.04em;
}
.iconContainer {
  font-size: 20px;
  display: flex;
  margin-right: 5px;
}
