.container {
}

.content {
  padding: 5px;
  background-color: white;
  height: 59vh;
  width: 75vw;
  min-width: 362px;
  max-height: 662px;
  overflow: auto;
}

.movementsContainer{
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
}

.selectedProductInfo {
  background-color: rgb(0, 0, 0);
  width: 75vw;
  min-width: 362px;
  max-height: 662px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
  }
}

.seletedProductInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remainingInfo {
  background-color: #acaedd;
  width: 75vw;
  min-width: 362px;
  max-height: 662px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
    font-size: 25px;
  }
}
.remainingInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}

.intPopup {
  > div {
    margin-top: 30px;
  }
}

.keybdContainer {
}


.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;
  pointer-events: none;
  svg {
    position: absolute;
    top: 60px;
    width: 100px;
    height: 100px;
  }
}

.addingElemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;
  pointer-events: none;
  svg {
    position: absolute;
    top: 66px;
    width: 100px;
    height: 100px;
  }
}

.shopfloorBaseLocation{
  height: 195px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 25px;
  border-color: #5978f6;
  border-radius: 15px 15px 15px 15px;
  border-style: solid;
  border-width: 1px;
 
  cursor:pointer;
}
.shopfloorBaseLocation:hover{
  background-color: #5136b329;
}

.centeredShopfloorElement{
  display:flex;
  align-items: center;

}

.shopfloorBase{
  pointer-events: none;
}

.iconShopfloorBaseEdit{
  cursor: pointer;
  color:rgb(60, 0, 255);
}

.avQtyLabel{
  font-size: 10px;
  margin-right:5px;
}

.availableQtyContainer{
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color:black;
}

.addingElement{
  color: rgba(61, 61, 61, 0.03);
  min-width: 260px;
  max-width: 302px;
  width: 90vw;

  border-style: solid;
  border-width: 1px;
  border-color: rgb(24, 14, 80);
  border-radius: 4px 4px 4px 4px;

}

.avQtyObj{
  color:black;
}
.maxBtn{
  color:rgb(75, 15, 75);
  cursor: pointer;
  font-weight: 600;
}
.maxBtnContainer{

  display:flex;
  justify-content: flex-end;
}
.elemElement {
  color: rgba(61, 61, 61, 0.03);
  min-width: 260px;
  max-width: 302px;
  width: 90vw;
  height: 231px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(24, 14, 80);
  border-radius: 4px 4px 4px 4px;
}

.pcqt{
  margin-top:5px;
  position:relative;
}

.pcqtAdded{
  margin-top: 5px;
    position: absolute;
    bottom: 17px;
    width: 89%;
}

.addedMovementContainer{
  margin-top:10px;
  margin-bottom:10px;
  margin-left:5px;
  margin-right:5px;
}

.locDiv{
  color:black;
}

.elemElement:hover {


  /*.elemElementTitle{
        display:none;
    }

    .elemElementDescription{
        display:none;
    }*/
}

.stockLocs{
  color:black;
  >div{
    margin-bottom: 9px;
  }
}

.spaceFiller{
  padding-bottom:25px;
}

.btnRemoveMovementContainer{
  position: relative;
  float: right;
  right: -13px;
  top: -14px;

}

.removeIcon{
  color: white;
    width: 45px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #8f3a51;
    border-radius:5px;
}

.removeIcon:hover{
  background-color: #5a1b2c;
}
.btnRemoveMovement{
  position: absolute;
    color: white;
    border-radius:5px;
}