.fileBtn{
    width:170px;
    height:115px;
    border-style:solid;
    border-width:1px;
    border-color:rgb(166, 164, 235);
    border-radius:16px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;


}

.progressDownloadVal{
    background-color: rgb(18, 65, 18);
    border-radius:16px;
    padding:5px;
    color:white;
}

.progressDownloadContainer{


}
.iconFileStyle{
    width:32px;
    height:32px;
}
.renderfileFrameContainer{
    margin:0px;padding:0px;overflow:hidden;
}
.renderFileFrame{
   overflow:hidden;
   overflow-x:hidden;
   overflow-y:hidden;
   height:94%;
   width:100%;
   position:absolute;
   top:45px;
   left:0px;
   right:0px;
   bottom:0px;
}

.downloadingBar{
    height:20px;
    background-color: rgb(8, 35, 7);
    width:100%;
}
.downloadedProgress{
    background-color: rgb(115, 175, 115);
    height:20px;
    
}
.pgLabel{
    height:0px;
    width:100%;
    position:relative;
    display: flex;
    justify-content: center;
    top:-18px;
    color:white;
    mix-blend-mode: difference;
    font-weight: 900;
}
.ripples{
    border-radius:16px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.centerContent{
    display:flex;
    justify-content: center;
}

.fileBtnLabel{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:3px;
}
.fileContainer{
    margin:5px;
}

.itemsContainer{
    margin-top:20px;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
}

.imageMediaContainer{
    height:100%;
    width:100%;
}

.videoMediaContainer{
    width:100%;
    height:100%;
}

.bottomOpenBtn{
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-weight:600;
    background-color: rgb(35, 35, 107);
    color:white;
    
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.bottomOpenBtn:hover{
    background-color: rgb(99, 99, 197);
    cursor:pointer;
}