.descIconContainer {
  display: flex;
  align-items: center;
}
.disabledBtn {
  background-color: #b1b1b1;
}
.outerContainerUnselected {
  border-color: rgba(0, 0, 0, 0.246);
  border-width: 1px;
}
.outerContainerSelected {
  border-color: rgb(0, 0, 0);
  border-width: 4px;
}
.outerContainer {
  border-style: solid;

  border-radius: 15px 15px 15px 15px;
  min-width: 175px;
  max-width: 315px;
  margin: 5px;
  overflow: hidden;

  > div {
    display: flex !important;
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.addIconContainer {
}

.codeNameContainer {
  margin: 15px 5px 15px 5px;
}

.rippleWide {
  width: 100%;
  height: 100%;
}

.code {
  font-size: 10px;
}
