
.confirmButtonContainer {
  display: flex;
  justify-content: center;
}

.confirmationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;



  @media (max-width: 600px) {
    /* Your CSS rules for screens smaller than 600px width */
    .confirmButton {
      font-size:20px;
      min-width: 100px;
      max-width: 150px;
    }
  }
  @media (min-width: 601px) {
    /* Your CSS rules for screens smaller than 600px width */
    .confirmButton {
      min-width: 175px;
      max-width: 250px;
      font-size: 25px;
    }
  }
    
  .confirmButton {
    min-height: 80px;
    height: 5vh;
 
    min-width: 170px;
   
 
    margin: 30px;
  }
  .pauseButton {
    min-height: 80px;
    min-width: 80px;
  }
}


.plusOneDone{
  font-size:60px !important;
}

.confirmBtnPlusOne{
  background-color: rgb(155, 199, 114);
}

.plusOneBtnStyles{
  min-height:135px !important;
}

.confirmationToolbar{
  

}



.toolbarDivider{
  width:100%;
  height:2px;
  background-color: #d1cbcb61;
}

.confirmationToolbarContainer{
  height:60px;
  display:flex;
  justify-content: center;
}

.conftoolCounterPlusOne{
  padding-left:20px;
  padding-right:20px;
  display:flex;
  justify-content: center;
  background-color: #aca3a34f;
  align-items: center;
  font-size:35px;
}