.contentWidthDimensions{
  width: 75vw;
  min-width: 380px;
  max-width: 850px;
}
.maintenanceTitl{
  font-size:20px;
  display:flex;
  justify-content: center;
}
.maintenanceTitleContainer{
  background-color: rgb(54, 54, 175);
  color:white;
}
.customFilterContainer{
  background-color:white;
  border-right:0px;
  border-top:0px;
  border-left:0px;
  border-bottom:2px;
  border-style:solid;
  border-color:rgb(187, 177, 177);
  >div{
    padding:8px;
  }
}
.content {
    padding: 5px;
    background-color: white;



    max-height: 540px;
    overflow: auto;
  }

  @media (min-height:535px) { 
    .content{
      min-height: 360px;
      height:55vh;
    }

  }

  @media (max-height:535px) { 
    .content{
      height: 280px;
     
    }

  }


  .consumptionMatContainer{
    background-color: rgb(100 100 173 / 16%);
    margin:5px;
    border-radius:16px;
  }

  .consumptionMatContainerBox{
    margin:5px;
  }

  .inputBoxContainer{
    margin-top:3px;
  }