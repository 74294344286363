.container {
  width: 100%;
  background-color: white;
  height: 12vh;
  position:relative;
  top: -15px;
  border-radius: 0px 0px 16px 16px;
}

.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.verifyingStockLabel {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}
