.container {
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
  margin-left: 23px;
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}
.loadingContainer {
  background-color: white;
}
.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}

.addedPopsSection {
  background-color: white;
}

.addedPops {
  display: flex;
  background-color: white;
  overflow: auto;
  min-width: 380px;
  width: 95vw;
}

.addedPop {
  white-space: nowrap;
  margin: 5px;
  border-radius: 5px;
  background-color: var(--ion-color-primary);
  display: flex;
  align-items: center;
}

.popName {
  margin: 10px;
  color: white;
}

.popRemoveBtnContainer {
  margin: 10px;
}

.popRemoveBtn {
  width: 50px;
}

.loadingDivContent {
  padding: 5px;
  background-color: white;
  width: 95vw;
  @media screen and (max-height: 705px) {
    height: 379px;
  }
  @media screen and (min-height: 705px) {
    min-height: 512px;
    height: 57vh;
  }
  min-width: 380px;
  max-height: 820px;

  overflow: auto;
}
