.content {
    padding: 5px;
    background-color: white;
    width: 58vw;
    height: 30vh;
    min-width: 346px;
    max-width: 550px;
    max-height: 660px;
    overflow: auto;
  }
 
  .container {
  }
  
  .keyPad {
    height: 300px;
  }
  
  .selectionContainer {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 540px) {
      justify-content: center;
    }
  }
  
  .lineBreaker {
    width: 100%;
    height: 2px;
    background-color: rgba(88, 86, 86, 0.096);
  }
  
  .confirmationNotice{
    display:flex;
    justify-content: center;
    font-size:20px;
    align-items: center;
    height:85%;
  }