.content{
    margin:20px;
}

.locName{
    font-size:12px;
    font-weight:600;
}

.expirationLabel{
    background-color: #c0d5ff;
}

.markRed{
    background-color: #ffe1e1;
}