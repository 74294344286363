@use "/src/theme/haxify.scss" as hxf;
.productionStartPreviewContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  width: 60vw;
  min-height: 53px;
  max-width: 400px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.externalOperation{
  background-color: #efefd9b3;
}

.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
  margin-left:20px;
  margin-right:20px;
}

.parameterizedVariablesContainer,
.fixedVariablesContainer{
  display:flex;
  justify-content: left;
  flex-wrap:wrap;
  margin-left:15px;
  margin-right:15px;
}

.productInformation,
.productPvInformation,
.productFvInformation {
  display: flex;
  justify-content: flex-start;
  padding-top: 5px;
  font-size: 12px;

  overflow: hidden;
  .productLabel,
  .fixedVariableName {
    color: black;
    margin-right: 5px;
    font-weight: bold;
  }
  .productCodeName,
  .fixedVarValue {
    margin-right: 5px;
    color: black;
    overflow: hidden;
    word-wrap: break-word;
  }
}

.productionOrderInformation {
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
  padding-top: 5px;
  font-size: 12px;
  .productionOrderLabel {
    color: black;
    margin-right: 5px;
    font-weight: bold;
  }
  .productionOrderCodeName {
    color: black;
  }
}

.startProductionButtonContainer {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.startProductionButtonOverrider {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  max-width: 200px;
}

.iconElement{
  position: absolute;
  top: 30px;
  width: 50px;
  height: 50px;
}
.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;
  position: relative;
  z-index: -1;
  top: -10px;

  svg {
    position: absolute;
    top: -5px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.operationsSelection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  grid-gap: 1em;
  @media all and (max-width: 450px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  @media all and (min-width: 450px) {
    margin-top: 20px;
    margin-bottom:20px;
    width: 90vw;
  }
}

.operationsContainer {
  display: flex;
  justify-content: center;
}

.progressBarContainer {
  position: absolute;
  bottom: 10px;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}
.fillerBar {
  overflow: hidden;
  position: relative;
  background-color:  rgb(43 37 141 / 25%);
  /*width:50%;*/
  height: 29px;
  left: -1px;
  z-index: -1;
  border-width: 1px;
  border-radius: 25px;
}

.elemElementOperation {
  color: rgba(61, 61, 61, 0.08);
  width: 200px;
  height: 130px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: #2d3658;
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
  overflow:hidden;
}

.elemElementOperationDisabled {
  background-color: rgba(0, 0, 0, 0.397);
}

.repositionedOperationsDone{
  position: relative;
  top: 50px;
  left: -25px;

}

.operationElementContent{

}

.elemElementTitle, .elemElementTitleMinimized {
  color: black;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}
.elemElementTitle {
  height:74px;
  font-weight:600;

}

.elemElementTitleMinimized{
  height:44px;
}

.elemElementDescription {
  color: black;

  text-align: center;
  font-size: 20px;
  border: solid;
  border-width: 1px;
  border-radius: 25px;
  width: 120px;
  height: 30px;
}

.elemElementOperationHover:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.pendingActiveProductions:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
}

.productionOrderStatus {
  display: flex;
  .poStatusLabel {
    color: black;
    font-size: 20px;
  }

  .poInProgress {
    margin-left: 5px;
    color: rgb(185, 131, 31);
    font-size: 20px;
  }

  .poComplete {
    margin-left: 5px;
    color: rgb(27, 131, 41);
    font-size: 20px;
  }
}

.flowSection {
  margin-top: 20px;
}


.operationTopNotifications{
  position:absolute;
  z-index:2;

}

.repositionNotfs{
  display:flex;
  position:relative;
  left: -9px;
  top: -16px;
  z-index:1;
}

.pendingExecutionsContainer{
  display: flex;
  align-items: center;
  background-color: #f5b260;
  border-radius: 7px;
  font-size: 22px;
  color: white;
  margin-right:5px;
}

.irregularitiesStatusContainer{
  display: flex;
  align-items: center;
  background-color: #f56060;
  border-radius: 7px;
  font-size: 22px;
  color: white;
  margin-right:5px;
  padding:2px;
  svg{
    font-size:22px;
    position:relative;
    top:3px;

    padding:2px;
  }
}

.externalIndicatorContainer{
  display: flex;
  align-items: center;
  background-color: #696d37;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin-right:5px;
}
.externalLabel{
  margin-right:5px;
}

.iconLabel{
  position:absolute;
  font-size:13px;
  top:-10px;
  font-weight:600;
  background-color:black;
  color:white;
  border-radius:10px;
  padding:2px;
}

.stockIconStatus{
  display: flex;
  align-items: center;
  background-color: #4638b3;
  border-radius: 7px;
  font-size: 22px;
  color: white;
  margin-right:5px;
  padding:2px;
  svg{
    font-size:22px;
    position:relative;
    top:3px;

    padding:2px;
  }
}