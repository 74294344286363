@use "/src/theme/haxify.scss" as hxf;

.container {
}

.content {
  padding: 5px;
  background-color: white;
  width: 44vw;
  min-width: 347px;
  max-width: 850px;
  max-height: 660px;
  overflow: auto;
}

.selectedProductInfo {
  background-color: rgb(33, 87, 189);
  width: 44vw;
  min-width: 347px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
  }
}
.seletedProductInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}

.iconCamera {
  width: 62px;
  height: 62px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
}
.iconCamera:hover {
  background-color: #88a4d1;
}

.keybdContainer {
}

.featureBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: -5px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
  svg:hover {
  }
}

.featureElement {
  color: rgba(61, 61, 61, 0.15);
  width: 200px;
  height: 100px;
  min-height: 90px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.featureElementSelected {
  border-width: 3px;
  background-color: rgba(78, 195, 195, 0.123);
  border-color: #7a7a7a;
}
.featureElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
}

.selectorAreaContainer {
}
.selectorArea {
  flex-wrap: wrap;
  justify-content: flex-start;
  > div {
    display: flex;
    justify-content: center;
    margin: 10px;
  }
}

.scantip {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin: 20px;
}
