@use "/src/theme/haxify.scss" as hxf;

.container {
}

.content {
  padding: 5px;
  background-color: white;
  width: 44vw;
  height:74vh;
  min-width: 347px;
  max-width: 450px;
  max-height: 450px;
  overflow: auto;
}
.stockLocationContainer{
  border-color:rgb(70, 70, 231);
  border-style: solid;
  border-width:1px;
  border-radius:10px;
  margin-bottom:10px;
  margin-left:10px;
  margin-right:10px;
  padding:10px;
}

.qtyContainer{
  background-color: rgba(63, 63, 212, 0.706);;
  color:white;
  font-size:30px;
  text-align: center;
  border-radius:10px;
}
.confirmButton{
  width:100%;
}
.selectedProductInfo {
  

  background-color: rgb(33, 87, 189);
  width: 44vw;
  min-width: 347px;
  max-width: 450px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
  }
}
.selectedProductInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}

.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}


