.content{
    margin:10px;
}

.topBtns{
    display:flex;

    flex-wrap:wrap;
    align-items: center;
}
.newLocBtn{
    margin-left:auto;
}
.locLabelTitle{
    font-size:10px;
    font-weight: 600;
}

.selectedLocationContainer{
    border-style:dashed;
    border-width:3px;
    border-color:rgb(78, 78, 61);
    border-radius:10px;
    padding:5px;
    background-color: #e4ecff;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.singleLineOverFlowElipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:150px;
}
.locName{
    font-size:13px;
    font-weight:600;
}
.addedQt{
color:green;
font-size:20px;
}

.canceledRow{
    background-color: rgb(217, 169, 169);
}

.closeBtn{
    button{
        color: gray;
    }
}

.warnConsideredFinished{
    color:green;
    font-size:20px;
}

.totalCountedLabel{
    font-size:13px;
    font-weight:600;
}

.totalCountedValue{
    background-color: rgb(30, 30, 100);
    color:white;
    font-weight:600;
    border-radius:10px;
    padding:5px;
    margin-top:5px;
}
.productNameCode{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}