@use "/src/theme/haxify.scss" as hxf;
.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: -5px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  width: 250px;
  height: 107px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.elemElementDescription {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
}
.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}
.spaceArrow {
  margin-left: 20px;
}

.elemElementTitle {
  /* white-space: nowrap;
    width: 100%;
    overflow: hidden;       
    text-overflow:    ellipsis;*/
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  height: 40px;
}

.selectedActiveProductionElement {
  background-color: rgba(78, 195, 195, 0.123);
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.739);
}

.operationsSelectionSection {
  margin-left: 20px;
  display: flex;
}

.content {
  padding: 5px;
  background-color: white;
  width: 95vw;
  @media screen and (max-height: 764px) {
    min-height: 379px;
    height: 60vh;
  }
  @media screen and (min-height: 765px) {
    min-height: 512px;
    height: 57vh;
  }
  min-width: 380px;
  max-height: 820px;

  overflow: auto;
}

.activeProductionElement {
  margin-top: 20px;
}
.operationsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.contentTitle {
  display: flex;
  justify-content: center;
  font-size: 23px;
  margin-top: 10px;
}
.container {
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.selectedOperationInfo {
  background-color: rgb(33, 87, 189);
  display: flex;
  justify-content: center;
  font-size: 26px;
  color: white;
  div {
    margin: 4px;
  }
}
