
.preloadedFromContainer{
    margin:10px;
    padding:10px;
    border-radius:10px;
    border-color:rgb(36, 44, 157);
    border-style:solid;
    border-width:1px;
    font-size:13px;
    display:flex;
    align-items: center;
    background-color: #def0ff;
  }
  
  
  .cancelPreload{
    color:#bf6666;
    cursor:pointer;
  }