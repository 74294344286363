

.content {
  padding: 5px;
  background-color: white;
  width: 44vw;
  min-width: 347px;
  max-width: 850px;
  max-height: 660px;
  overflow: auto;
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}

.intPopup {
  > div {
    margin-top: 20px;
  }
}


.confirmButton{
    height:80px;
    margin-top: 20px;
}

.confirmationContent{
    display:flex;
    justify-content: center;
    margin-bottom:20px;
}