@use "/src/theme/haxify.scss" as hxf;

.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.optionsContainer {
  position: absolute;
  top: 37%;
  transform: translateY(-37%);
  width: 100%;
}

.optionsContent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.elemBackgroundContainer {
  position:relative;
  top:-15px;
  right:-10px;
  width:100%;

}

.iconContainer{
  position:absolute;
  right:0px;
  background-color:rgb(86, 86, 209);
  border-radius:10px;
  width:25px;
  height:25px;
  padding:5px;
  color:white;

}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  min-width: 190px;
  height: 100px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;

  margin: 10px;
}

.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
  margin: 10px;
  padding-top: 50px;
}
