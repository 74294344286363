.topBar{
    width:100%;
    background-color: #4b124d;
    position:fixed;
    height:25px;
    color:white;
    text-align: center;
    font-size:20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

.topMessage{
    display:flex;
    justify-content: center;
    font-size:20px;
    font-weight: 600;
}

.stagedLot{
    border-radius:10px;
    border-width:1px;
    border-style:solid;
    border-color:#adaae5;
    height:165px;

    width:200px;
    margin:10px;
    padding:5px;
}

.stagedLotsSelection{
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
}

.stagedLotNaming{
    display:flex;
    justify-content: center;
    font-size:13px;
    font-weight:600;
    color:#4c46bc;
    margin-bottom:5px;
}
.stagedLotNameLabel{

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fieldValue{
    display:flex;
    align-items: center;
  
    border-radius:10px;
}
.fieldValueOK{
    background-color: #eae9f9;
}
.fieldValueBad{
    background-color: #f9e9e9;
}

.labelInfo{
    font-size:13px;
    font-weight:600;
}

.selectMeIcon{
    position: absolute;
    height: 0px;
    left: 94px;
    bottom: 20px;

}

.selectMeIconStyling{
    background-color: #6c62eb;
    color:white;
    border-radius:100%;
    width:24px;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:3px;
    >svg{
        font-size:16px;
    }
}
.doNotSelectMeIconStyling{
    background-color: #eb6262;
    color:white;
    border-radius:100%;
    width:24px;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:3px;
    >svg{
        font-size:16px;
    }
}
.stagedLot:hover{
    background-color: rgb(248, 246, 255);
    cursor:pointer;
}

.relevantValues{
    font-size:14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}