@use "/src/theme/haxify.scss" as hxf;

.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.productionOrdersContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.productionOrdersSelection {
  //overflow:auto;
  height: 100%;
  /*border-style: solid;
    border-width:1px;
    border-color:black;
    border-radius: 15px 15px 15px 15px;*/
  /* max-width:90vw;
    display:flex;
    justify-content: flex-start;
    flex-wrap: wrap;*/
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 10px;
  @media all and (max-width: 450px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  @media all and (min-width: 450px) {
    margin-top: 17px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
  /*background-image:
    repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
    repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
    background-size: 71px 71px;*/
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;

  svg {
    position: absolute;
    top: -5px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  min-width: 230px;
  height: 140px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.scannerDeviceElemTitle{
  margin-top:20px;
  display:flex;
  justify-content: center;
  font-size:20px;
}
.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
        display:none;
    }

    .elemElementDescription{
        display:none;
    }*/
}

.label{
  color:black;
  font-weight: bold;
}
.labelContent{
  color:black;
}

.poCodeName{
  color: black;
    height: 37px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
  margin: 10px;
  padding-top: 50px;
}


.stagedProgressContainer{
  position: absolute;
    width: 86%;
    bottom: 11px;
    color:black;
}