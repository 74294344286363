.toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;

  padding-right: 15px;
  padding-left: 15px;
}

.toolbarProfilePicture {
  background-color: black;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.rightCornerElements {
  display: flex;
}

.toolbarWorkersMode,
.toolbarLangSwitcher,
.toolbarSessionManagerSection,
.toolbarWorkerAuthenticated {
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    padding-right: 10px;
  }
  padding-top: 2px;
  padding-bottom: 2px;
 
}

.toolbarLogo {
  min-width: 90px;
  min-height: 40px;
  /*width: 66px;*/
  height: 25px;
}



.toolbarWorkerAuthenticated:hover {
  background-color: #cbcbef82;
  border-radius: 10px;
}

.toolbarLangSection,
.toolbarProfileAccountNameSection {
  padding:6px;
  
}

.toolbarLangSwitcher:hover,
.toolbarSessionManagerSection:hover {
  background-color: #cbcbef82;
  border-radius: 10px;
}

.toolbarLangSection:hover,
.toolbarProfileAccountNameSection:hover{

  padding:6px;
}
.toolbarElement {
  display: flex;
  
}
.toolbarProfileAccount {
  font-size: 14px;
  line-height: normal;
}

.toolbarPersonName {
  line-height: normal;
}

.footerLabel {
  font-size: 10px;
}

.offlineModeBar {
  padding-top: 10px;
  width: 100%;
  padding-bottom: 10px;
}

.offlineLabel1 {
  color: wheat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offlineLabel1Red {
  color: rgb(167, 28, 28);
  display: flex;
  justify-content: center;
  align-items: center;
}
.offlineLabelMaintenance1 {
  color: rgb(109, 12, 45);
  display: flex;
  justify-content: center;
  align-items: center;
}

.offlineLabel2 {
  display: flex;
  justify-content: center;
  font-size: 10px;
  color: white;
  margin-left: 5px;
}

.offlineModeBarContainer {
  background-color: gray;
}

.offlineIcon {
  padding-right: 5px;
}

.adminMinibar{
  background-color: red;
  padding:2px;
  position:absolute;
  margin-left:47%;
  border-radius:20px;
}

.toolbarHome{
  color:#2d3658;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left:10px;
    padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #e3e3ff45;
  border-radius:10px;
}
.toolbarHome:hover{
  cursor:pointer;
  background-color: #cbcbef82;
}