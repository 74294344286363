
.confirmButtonContainer {
  display: flex;
  justify-content: center;
}

.confirmationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

}

.customConfirmationContainer {
    margin: 10px;
}

.bottomAreaContainer {
  > div {
    display: flex;
    justify-content: center;
  }

  background-color: white;


  min-width: 350px;
  max-height: 400px;
  max-width: 400px;
  top: -16px;
  position:relative
}
.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}


.continueBtn{
  display:flex;
  justify-content: center;

}

.customChecklistComboSelectOptions{
  min-width:160px;
}

.inputContainer{
  margin-top: 10px;
}