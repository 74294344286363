.confirmButton {
}

.confirmButtonContainer {
  display: flex;
  justify-content: center;
}

.confirmationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  .confirmButton {
    min-height: 80px;
    height: 5vh;
    width: 60vw;
    max-width: 250px;
    min-width: 175px;
    font-size: 25px;
    margin: 30px;
  }
  .pauseButton {
    min-height: 80px;
    min-width: 80px;
  }
}

.customConfirmationContainer {
  > :nth-child(1) {
    min-height: 80px;
    height: 5vh;

    /* width: 100%;
        max-width: 180px;
        min-width: 100px;*/
    font-size: 25px;
    margin: 10px;
  }
}

.bottomAreaContainer {
  > div {
    display: flex;
    justify-content: center;
  }

  background-color: white;

  width: 44vw;
  min-width: 347px;
  max-width: 850px;
  max-height: 660px;
}
.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}
