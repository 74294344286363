.container {
}

.content {
  padding: 5px;
  background-color: white;

  height: 57vh;
  min-width: 233px;
  max-width: 455px;
  max-height: 642px;
  overflow: auto;
}

.locationSelectorArea {
  display: flex;
  justify-content: center;
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}

.intPopup {
  > div {
    margin-top: 20px;
  }
}
.productVariablesSection {
  display: flex;
  justify-content: center;
}

.productVariables {
  width: 100%;
}

.label {
  display: flex;
  justify-content: center;
  font-size:20px;
  font-weight: 600;
}

.pvContainer {
  margin-top: 10px;
}

.pvsSection {
  width: 100%;
}

.selectedProductInfo {
  width: 44vw;
  min-width: 347px;
  max-width: 455px;
  max-height: 660px;
  background-color: rgb(33, 87, 189);
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
  }
}

.selcetedProductInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.expectedVariantOption{
  background-color: rgb(158, 167, 237);
  border:2px;
  border-color:rgb(99, 97, 97);
  border-style:solid;
}

.expectedVariantOption:hover{
  background-color: rgb(196, 200, 230);
}

//


.bottomAreaContainer {
    > div {
      display: flex;
      justify-content: center;
    }
  
    background-color: white;
  
    width: 44vw;
    min-width: 347px;
    max-width: 455px;
    max-height: 660px;
    position: relative;
    top: -14px;
  }