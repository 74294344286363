.progressBarContainer {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}
.fillerBar {
  overflow: hidden;
  position: relative;
  background-color: rgb(43 37 141 / 25%);
  /*width:50%;*/
  left: -1px;
  z-index: 1;
  border-width: 1px;

}
.elemElementDescription {
  color: black;

  text-align: center;
  
  border: solid;
  border-width: 1px;
  border-radius: 25px;
}
