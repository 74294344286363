.progressBarContainer {

    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .barLabel {
    /*
    top: 15px;
    height: 0px;*/
    position: relative;
    /*position: absolute;*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 2;
    p {
      height:0px;
      position: relative;
      top: -12px;
      display: flex;
      justify-content: center;
    }
  }
  .fillerBar {
    overflow: hidden;
    position: relative;
    background-color: rgb(43 37 141 / 25%);
    /*width:50%;*/
    left: -1px;
    z-index: 1;
    border-width: 1px;
  
  }

  .divPgBContainer{
    height: 29px;
    width: 125px;
    top: 0px;
    position: absolute;
  }

  .elemElementDescription {
    color: black;
  
    text-align: center;
    
    border: solid;
    border-width: 1px;
    border-radius: 25px;
  }
  