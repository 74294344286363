.content {
    padding: 5px;
    background-color: white;
    width: 75vw;
    min-height: 340px;
    height:60vh;
    min-width: 380px;
    max-width: 850px;
    max-height: 540px;
    overflow: auto;
  }

  .askIfAssignedWork{
    font-size:24px;
    text-align: center;
  }

  .checkboxCustomInner{
    display:flex;
    align-items: center;
    margin-left:10px;
  }

  .pvCheckLabel{
    margin-left:10px;
  }

  .inputContainer{
    margin-top:15px;
  }

  .nameCl{
    font-size:25px;
    color:gray;
    display:flex;
    justify-content: center;
  }