@use "/src/theme/haxify.scss" as hxf;

.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.optionsContainer {
  position: absolute;
  top: 37%;
  transform: translateY(-37%);
  width: 100%;
}

.optionsContent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: -5px;
    color:hxf.$btn-bg-icons-colour;
    width: 100px;
    height: 100px;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  min-width: 190px;
  height: 100px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;

  margin: 10px;
}
.elementExit{
  background-color: #783d3d;
}
.elementExit:hover{
  background-color: #ba5b5b;
}
.elementEntry{
  background-color: #42783d;
}
.elementEntry:hover{
  background-color: #7cc975;
}
.elemElement:hover {

  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementTitle{
  color:white;
}
.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color:white;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
  margin: 10px;
  padding-top: 50px;
}

.successOut {
  display: flex;
  justify-content: center;
}

.successOutMsg {
  margin-top: 10vh;
  margin: 10vh 39px 39px 39px;
  font-size: 6vw;
}

@media screen and (min-width: 1000px) {
  .successOutMsg {
    font-size: 50px;
  }
}

.pleaseConfirmPunchclockSection {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.confirmMessage {
  font-size: 24px;
}

.confirmCountdown {
  font-size: 30px;
  display: flex;
  justify-content: center;
}
