
@use "/src/theme/haxify.scss" as hxf;

.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.productionOrdersContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.productionOrdersSelection {
  //overflow:auto;
  height: 100%;
  /*border-style: solid;
    border-width:1px;
    border-color:black;
    border-radius: 15px 15px 15px 15px;*/
  /* max-width:90vw;
    display:flex;
    justify-content: flex-start;
    flex-wrap: wrap;*/


    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    /*
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 23px;
  */
  @media all and (max-width: 585px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  @media all and (min-width: 585px) {
    margin-top: 17px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
  /*background-image:
    repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
    repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
    background-size: 71px 71px;*/
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;

  svg {
    position: absolute;
    top: 9px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.elemElement {
  color: hxf.$hxfLightCol2;
  min-width: 265px;
  max-width:280px;
  height: 140px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: hxf.$hxfDarkCol1;
  border-radius: 15px 15px 15px 15px;
  margin-right: 20px;
  margin-bottom:20px;
  overflow:hidden;
}

.elemElementTitle{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height:50px;

  color: #2f2c4c;

}
.poCode{
  font-weight: 600;
}
.taskbarContainer{
  top: 52px;
  left: -27px;
  position: relative
}
.scannerDeviceElemTitle{
  margin-top:20px;
  display:flex;
  justify-content: center;
  font-size:20px;
}
.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
        display:none;
    }

    .elemElementDescription{
        display:none;
    }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size:14px;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
 
  
}

.otherProgressBar{
  height:40px;

}

.progressbarReposition{
  position: relative;
  top: -24px;
  left: -22px;
}

.fadedPo{
  opacity: 0.3;
  background-color: #d3dee7;
}

.noPosAvailable{
  display:flex;
  justify-content: center;
  margin-top:200px;
  color:gray;
  font-size:20px;
  margin-left:10px;
  margin-right:10px;
}

.reloadIcon{
  display:flex;
  justify-content: center;

  cursor:pointer;
  color:gray;
  font-size:20px;
  margin-top:10px;
  margin-left:10px;
  margin-right:10px;

}

.centerAvPo{
  display:flex;
  justify-content: center;
}

.reloadIcon >svg{
  width:100px;
  height:100px;
}

.customSearchBarContainer {
  margin-left: 10px;
  margin-right: 10px;
  max-width:565px;
  width:100%;
}

.topSearchbar{
  display:flex;
  justify-content: center;
  margin-top:100px;
}

.topActionsContainer{
  display:flex;
  justify-content: center;
  padding-top: 25px;
}