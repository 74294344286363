.topPageContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    align-items: center;
  }
  
  .pageTitle {
    font-size: 20px;
  }
  
  .cancelButton {
    margin-right: 20px;
    ion-button {
      width: 100%;
    }
  }
  
  .homeButton {
    margin-right: 20px;
    ion-button {
      width: 100%;
    }
  }
  
  .cornerButtons {
    display: flex;
  }
  .productionStartPreviewContainer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  
  .elemElement {
    width: 80vw;
    min-height: 50px;
    max-width: 560px;
    //width:20vw;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.246);
    border-radius: 15px 15px 15px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .elemTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 7px;
  }
  
  .separator {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.246);
  }
  
  .operationTakingTime {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    font-size:30px;
    width:100%;
  }
  
  .operationDescription {
    display: flex;
    justify-content: center;
    margin: 18px;
    word-break: break-all;
  }
  
  .overlayContainer {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.37);
    position: fixed;
    overflow: hidden;
    top: 0;
    z-index: 1;
  }
  