.content {
    padding: 5px;
    background-color: white;
  
    height: 60vh;
  
   
    @media only screen and (min-width: 540px) {
      max-height: 640px;
    }
  
    @media only screen and (max-width: 540px) {

      @media only screen and (max-height: 770px) {
        max-height: 410px;
        
      }

    }
    overflow: auto;
    display: flex;
    justify-content: center;
  }
  
  .contentWidthSizes {

    min-width: 353px;
    max-width: 850px;
  }
  
  .label {
    text-align: center;
  }
  
  .container {
    width:100%;
  }
  
  .keyPad {
    height: 300px;
  }
  
  .lineBreaker {
    width: 100%;
    height: 2px;
    background-color: rgba(88, 86, 86, 0.096);
  }
  
  .checklistTitle {
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 40px;
  }
  
  .checklistField {
    /* display:flex;
      justify-content: center;*/
      display:flex;
      align-items: center;
  width:100%;
  }
  

  .parameterizedVarLabel {
    font-weight: 500;
    font-size: 25px;
    margin-right: 15px;
    margin-left: 10px;
    display: inline-block;
    text-align: right;
    width: 150px;
  }
  .parameterizedVarField {
    display: inline-block;
  }
  .checkboxCustom {
    > div > span > span > svg {
      height: 50px;
      width: 50px;
    }
  }
  
  .checkboxCustomInner {
    position: relative;
    left: -15px;
  }
  
  .customChecklistComboSelectOptions {

    min-width: 240px;

  }
  
  .checklistsConfirmationContainer {
    position:relative;
    top:-12px;
    border-radius:0px 0px 16px 16px;
    > div {
      display: flex;
      justify-content: center;
    }
  
    background-color: white;
  
    max-height: 660px;
    overflow: hidden;
    z-index:1;
  }
  
  .lineBreaker {
    width: 100%;
    height: 2px;
    background-color: rgba(88, 86, 86, 0.096);
  }
  

  .rangeContainer{
    display:flex;
    justify-content: space-between;
    color:gray;
  }

  .exceedErrorLim{
    color:red;
  }

  .contentPopupConfirm{
    padding: 5px;
    background-color: white;
  

  
    max-height: 660px;
    overflow: auto;
    display: flex;
    justify-content: center;
    min-width:360px;
  }

  .irregularityConfirmIcon{
    color:#c75f5f;
    display:flex;
    justify-content: center;
    
    >svg{
      height:75px;
      width:110px;
    }

  }

  .askConfirmIrregularityLabel{
    font-size:30px;
    text-align: center;
  }

  .iconContainer{
    margin:5px;
    >svg{
      width:40px;
      height:40px;
      
    }
  }

  .confirmBtnIrr{
    width:100%;
  }

  .matchFixVarContainer{
    min-height:100px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:150px;

  }

  .checklistsFieldsContainer{
    min-height: 100px;
    display: flex;

    align-items: center;
    position: relative;
    top: -10px;
  
  }

  .checklistFieldContainer{
    margin-top:5px;

  }

  .checklistFieldContainer {
    display: flex;
   
  }
  




.checklistsFieldsContainer{

  @media only screen and (min-width: 540px) {
 
    justify-content: flex-start;

  }

  @media only screen and (max-width: 540px) {
    justify-content: center;
  }
}

.noVarConf{
  margin:10px;
  font-size:20px;
  display:flex;
  justify-content: center;
}