.externalIndicatorContainer{
    display: flex;
    align-items: center;
    background-color: #696d37;
    border-radius: 7px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    margin-right:5px;
  }
  
  .externalLabel{
    margin-right:5px;
  }