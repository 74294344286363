.content {
  padding: 5px;
  background-color: white;
  width: 60vw;
  height: 642px;
  min-width: 380px;
  max-width: 400px;
  max-height: 642px;
  overflow: auto;
}

.label {
  text-align: center;
}

.productVariablesSection {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.productVariables {
  width: 100%;
}
.pvContainer {
  margin-top: 10px;
}
