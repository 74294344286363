.content {
  padding: 5px;
  background-color: white;
  width: 75vw;
  height: 660px;
  min-width: 380px;
  max-width: 850px;
  max-height: 540px;
  overflow: auto;
}
.contentQtyOcurrences {
  padding: 5px;
  background-color: white;
  width: 75vw;
  height: 680px;
  min-width: 380px;
  max-width: 850px;
  max-height: 680px;
  overflow: auto;
}
.contentIrregularitysSelect {
  padding: 5px;
  background-color: white;
  width: 75vw;
  height: 50vh;
  min-height: 330px;
  min-width: 380px;
  max-width: 850px;
  max-height: 520px;
  overflow: auto;
}
.label {
  text-align: center;
}

.productionTgtStock {
  div {
    display: flex;
    justify-content: center;
    margin: 2px;
  }
}

.container {
}

.keyPad {
  height: 300px;
}

.IrregularitysSelectionContainer {
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 540px) {
    justify-content: center;
  }
}

.IrregularitysSelectionOuter {
}
.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}

.addedIrregularitys {
  display: flex;
  background-color: white;
  overflow: auto;
  width: 75vw;
  max-width: 850px;
  min-width: 380px;
}
.addedIrregularity {
  margin: 5px;
  border-radius: 5px;
  background-color: var(--ion-color-primary);
  display: flex;
  align-items: center;
}

.IrregularityName {
  margin: 10px;
  color: white;
}

.IrregularityRemoveBtnContainer {
  margin: 10px;
}

.IrregularityRemoveBtn {
  width: 50px;
}

.properSubtitleWastes{


  display:flex;
  justify-content: center;
  align-items: center;
  width:100%;

  z-index:2;
  padding-top:10px;
  padding-bottom:10px;
  color:white;
  background-color: #624d40;
}

.topElementWasteStockLocation{
  background-color: #624d40;
  color:white;
  display:flex;
  justify-content: center;
  align-items: center;
}
