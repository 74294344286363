.container {
}

.content {
  padding: 5px;
  background-color: white;

  height: 57vh;
  width: 44vw;
  min-width: 347px;
  max-width: 850px;
  max-height: 660px;
  overflow: auto;
}

.locationSelectorArea {
  display: flex;
  justify-content: center;
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
    display:flex;
    justify-content: center;
    width:100%;
    position:relative;
    left:30px;
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}

.intPopup {
  > div {
    margin-top: 20px;
  }
}
.productVariablesSection {
  display: flex;
  justify-content: center;
}

.productVariables {
  width: 100%;
}

.label {
  display: flex;
  justify-content: center;
  font-size:20px;
  font-weight: 600;
}

.pvContainer {
  margin-top: 10px;
}

.pvsSection {
  width: 100%;
}

.selectedProductInfo {
  width: 44vw;
  min-width: 347px;
  max-width: 850px;
  max-height: 660px;
  background-color: #98c553;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
  }
}

.selcetedProductInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.iconTransform{
    display:flex;
    justify-content: center;
    margin-top:20px;
    margin-bottom:20px;
}

.appvsContainer{
    width:100%;

    border-width: 1px;
    border-radius: 8px;
    border-color: black;
    border-style: solid;
    display:flex;
    justify-content: center;
}

.transformedProductContainer{
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    padding: 5px;
    margin-bottom:10px;
    margin-top:10px;

}

.transformedProductInner{
  display:flex;
  justify-content: space-between;
}

.btnRemove{
    width:100%;
    height:50px;
    font-size:20px;
}

.qtyManageContainer{
  display:flex;
  margin-top:20px;
}

.confirmButton{
  font-size:15px;
}

.addrembtn{
  height:55px;
  position:relative;
  top:-4px;
}