.progressBarContainer {
  width: 100%;
  background-color: rgba(74, 97, 124, 0.335);
  border-color: black;
  border-width: 1px;
}

.pgBorderStyleDefault {
  border-radius: 25px 25px 25px 25px;
}
.progressBarFill {
  width: 50%;
  height: 100%;
  background-color: green;

  z-index: -1;
}

.progressLabel {
  font-weight: 600;
  color: white;
  display: flex;
  justify-content: center;
}
