.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.receivingOrdersContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.objsSelection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 950px;
}

.objElement {
  margin: 5px;
}
.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: 45px;
    width: 100px;
    height: 100px;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);

  width: 284px;
  height: 250px;

  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
  margin: 10px;
  padding-top: 50px;
}
.settingsContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}

.customSearchBarContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.searchIconStyles {
}

.progressBarContainer {
  position: absolute;
  bottom: 10px;
  width: 225px;
}

.appliedFiltersContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.productNameCodeContainer {
  color: black;
  align-items: center;
}

.productNameCode {
  margin-left: 5px;
}

.orderLabel {
  display: flex;
  justify-content: flex-start;
}
.orderTopLabel {
  display: flex;
  justify-content: flex-start;
}

.singleLineOverFlowElipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remainingQtyLabel {
  display: flex;
  justify-content: center;
  color: black;
  margin-top: 5px;
  font-size: 10px;
  font-weight: 900;
}

.qtyRemContainer {
  display: flex;
  justify-content: center;
  color: rgb(26, 112, 23);
  font-size: 35px;
  > div {
    margin: 2px;
  }
}

.unitValueContainer{
  display: flex;
  justify-content: center;
  color:black;
  font-size: 13px;
  > div {
    margin: 2px;
  }
}

.positionBottom {

  position: absolute;
  bottom: 40px;
  width: 86%;
}

.positionValueInfo{
  background-color: rgb(191, 191, 191);
  border-radius:10px;
  padding:1px;
  position:absolute;
  bottom:2px;
}

.dateMovementContainer {
  color: black;
  font-size: 15px;
}

.parameterizedVariablesContainer {
  font-size: 13px;
}

.infoAndSelection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.objLocationContainer {
  margin: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.objLocation {
  background-color: rgb(40, 40, 131);
  color: white;
  border-radius: 5px;
  min-width: 214px;
  max-width: 304px;
}

.modifyLocationBtn {
  margin: 20px;
}
.locDiv {
  margin-top: 5px;
}
.objLocationContent {
  margin: 20px;
}

.rlocTitle {
  font-size: 25px;
}

.noItemsAvailable {
  display: flex;
  margin-top: 37vh;

  justify-content: center;
}
