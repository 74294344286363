.operationInfo {
  display: flex;
}

.currentOperationContainer {
  width: 400vw;

  height: 25px;
  color: white;
  display: flex;
  overflow: hidden;
}
.currentOperation {
  width: 100%;
  background-color: rgb(60, 95, 60);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nextOperation {
  width: 120%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.triangleCorner {
  width: 0;
  height: 0px;
  border-left: 19px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 42px solid #3c5f3c;
  transform: rotate(90deg);
  position: relative;
  left: -16px;
  top: -3px;
}

.productionOrderInfo {
  background-color: rgb(2, 80, 144);
  color: white;
  display: flex;
  justify-content: center;
  padding: 6px;
}
.productInfo {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  padding: 2px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
}
.bottomInfo {
  width: 100%;
  background-color: white;
}
