

.opaMFVContainer{
    border-width:2px;
    border-style:solid;
    border-color:rgb(26, 26, 158);
    margin:10px;
 
    min-width:150px;
  }

.fvTitle{
  background-color: #5c5555;
  color:white;
  text-align: center;
  padding:2px;
  font-size:17px;
  
}

.opaMFVSList{
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
}
.opaTitle{
  font-size:24px;
  margin-top:7px;
  text-align: center;
}
.limitsMFVS{
  display:flex;

  justify-content: space-between;
}

.mfvMinVal{
  background-color: rgb(239, 198, 198);
  
  padding-right:2px;
  padding-bottom:2px;
  padding-top:2px;
  padding-left:2px;
  width:100%;
  display:flex;
  justify-content: start;
}


.mfvMaxVal{
  background-color: rgb(232, 165, 165);
  padding-left:4px;
  padding-bottom:2px;
  padding-top:2px;
  padding-right:2px;
  width:100%;
  display:flex;
  justify-content: end;
}

.mfvCentral{
  padding-right:2px;
  padding-left:2px;
  padding-top:2px;
  background-color: rgb(211, 237, 222);
}

.mfvIconCheck{
  display:flex;
  justify-content: center;
}

.topBar{
  width:100%;
  background-color: #4040d4;
  text-align: center;
  font-size:20px;
  color:white;
}