.operationInfo {
  display: flex;
}

.currentOperationContainer {
  width: 400vw;

  height: 50px;
  color: white;
  display: flex;
  overflow: hidden;
}
.currentOperation {
  width: 100%;
  background-color: rgb(60, 95, 60);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nextOperation {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.triangleCorner {
  width: 0;
  height: 0px;
  border-left: 38px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 42px solid #3c5f3c;
  transform: rotate(90deg);
  position: relative;
  left: -27px;
  top: 9px;
}

.productionOrderInfo {
  background-color: var(--ion-color-primary);
  color: white;
  display: flex;
  justify-content: center;
  padding: 6px;
}
.productInfo {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  padding: 2px;
  font-size:23px;
}
