.contentDiv {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, black 0%, #0d1718 72%, #0d1213 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.exitSo {
  color: white;
  position: absolute;
  top: -44px;
  width: 240px;
  font-size: 21px;
  display: flex;
  cursor: pointer;
}
.containerDiv {
  display: flex;
  width: 94vw;
  max-width: 1200px;
  border-radius: 10px 10px 10px 10px;

  > div:nth-child(1) {
  }
}

.divAuth {
  width: 100%;
  border-radius: 10px 0px 0px 10px;
  background-color: rgb(255, 255, 255);

  .logoDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    > div {
      font-size: 23px;
    }
  }

  .connectWithLabel {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 900;
    display: flex;
    justify-content: flex-start;
    margin-left: 11%;
    font-size: 16px;
  }

  .connectWithSection {
    display: flex;
    justify-content: center;
    > div {
      padding: 20px;
      > div {
        > span {
          background-color: #122230;
        }
      }
    }
  }

  .connectWithSelection {
    display: flex;
    justify-content: center;
  }
}

.connectButton {
  margin-top: 20px !important;
  height: 55px;
  width: 100%;
  background: linear-gradient(to right, #122230 0%, rgb(9, 17, 24) 100%);
  color: white !important;
  font-weight: 900;
}

.connectField {
  width: 100%;
}

.loginFieldContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.loginField {
  display: flex;
  justify-content: center;
  width: 80%;
}

.formLogin {
  margin-bottom: 20px;
}
.fieldContainer {
  width: 100%;
}
.formContainer {
  width: 80%;
}

@media screen and (max-width: 657px) {
  .divAuth {
    border-radius: 10px;
  }
}

.divApp {
  width: 100%;
  border-radius: 0px 10px 10px 0px;
  background: linear-gradient(to right, #122230 0%, rgb(9, 17, 24) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 657px) {
  .divApp {
    display: none !important;
  }
}

.appTitle {
  font-family: Arial, Helvetica, sans-serif;

  font-size: 30px;
  font-weight: 900;
  color: white;
}

.appTitleDesc {
  font-weight: 900;
  color: white;
  font-size: 16px;
}

.divConnectForm {
  margin-top: 10px;
  margin-bottom: 100px;
}

.errorOccurred {
  margin-bottom: 10px;
  color: rgb(112, 22, 22);
}

.connectingConfirmationButton {
  text-align: center;
  margin: 20px;
  button {
    height: 55px;
    width: 85%;
  }
}

.continueButton {
  background: linear-gradient(to right, #122230 0%, rgb(9, 17, 24) 100%);
  color: white !important;
}

.timeoutError {
  text-align: center;
}

.haxifyConnectButton {
  width: 85%;
  background: linear-gradient(to right, #122230 0%, rgb(9, 17, 24) 100%);
  color: white !important;
}

.orContinueWith {
  margin-top: 25px;
  font-size: 26px;
  text-align: center;
}

.socialContinueDiv {
  min-width: 300px;
}

.socialContinue {
  margin-top: 20px !important;
  height: 55px;
  width: 100%;
  min-width: 300px;
  background: linear-gradient(to right, #122230 0%, rgb(9, 17, 24) 100%);
  color: white !important;
  > span:nth-child(1) {
    font-weight: 900;
    > span {
      display: flex;
      align-items: center;
      img {
        position: absolute;
        left: 1em;
      }
    }
  }
}
