.confirmButton {
}

.confirmButtonContainer {
  display: flex;
  justify-content: center;
  max-width:600px;
}

.confirmationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  .confirmButton {
    min-height: 80px;
    height: 5vh;
    width: 60vw;

    font-size: 25px;
    margin: 30px;
  }
  .pauseButton {
    min-height: 80px;
    min-width: 80px;
  }
}
