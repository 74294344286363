.container {
}

.content {
  padding: 5px;
  background-color: white;
  width: 60vw;

  min-width: 380px;
  max-width: 400px;
  max-height: 642px;
  overflow: auto;
}

.hasRecords{
  @media screen and (max-height: 675px) {
    height: 350px;
  }
  @media screen and (min-height: 676px) and (max-height: 780px) {
    height: 450px;
  }
  @media screen and (min-height: 781px) {
    height: 550px;
  }
}

.hasNoRecords{
  height:160px;
}

.activeProductionElement {
  margin-top: 20px;
  margin-bottom: 20px;
}
.activeProductionsContainer {
  display: flex;
  justify-content: center;
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}


.iconElement{
  position: absolute;
  top: 30px;
  width: 50px;
  height: 50px;
}

.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;
 


}


.cornerIconBtn{
  display: flex;
  justify-content: center;

  width: 100%;
  color:white;

  .cornerIconContainer{
    background-color: #43735b;
    position: absolute;
    top: -17px;
    border-radius: 14px;
    left: -16px;
    width: 33px;
    height: 34px;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  svg{

  }

}

.elemElement {
  color: rgba(61, 61, 61, 0.13);


  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  
}

.elemElementMyPr{
  background-color: #cbf9fb4f;
}

.elemElementCollab{
  background-color: #cbfbcb4f;
}

.elemElementCollab:hover{
  background-color: #dffbdf;
  cursor: pointer;
}

.elemElementMyPr:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementDescription{
  font-size:15px;
  font-weight:900;
}

.elemElementTitle {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:310px;
}

.selectedActiveProductionElement {

  border-width: 3px;
  border-color: rgb(39 28 129);
}

.activeProductionsSection {

}

.externalRecordContainer{
  background-color: #fcffe8;
}

.topTipInfo{
  display: flex;
  justify-content: flex-start;
  position: relative;
  top: -32px;
  margin-left:5px;
  z-index: 1;
  height: 0px;
}

.mfvPendingApprovalValuesContainer{
  background-color: #66c158;
  color:white;
  border-radius: 14px;
  padding-left:3px;
  padding-right:3px;
  max-width: 100px;
  height: 34px;
  display:flex;
  justify-content: center;
  align-items: center;

}