.pageContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.entitySelectionDiv {
  margin: 10vh 40px 40px 40px;
}
.entityGoBack {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10px;
  padding-bottom: 10px;
  div {
    display: flex;
    align-items: center;
  }
}
.entitySelectionContainer {
  width: 70vw;
  max-width: 500px;
  border-style: solid;
  border-width: 2px;
  border-radius: 15px 15px 15px 15px;
}

.entitySelHeaderSeparator {
  width: 100%;
  height: 3px;
  background-color: black;
}

.entitySelectionContainerHeader {
  display: flex;
  justify-content: center;
  padding: 20px;
  font-size: 20px;
}

.entityButtonArea {
  display: flex;
  justify-content: center;
  cursor: pointer;
  p {
    font-size: 20px;
    color: rgb(43, 100, 173);
  }
}

.buttonBorderBottom {
  color: black;
  border-style: solid;
  border-width: 0px 0px 3px 0px;
}

.buttonSelected {
  background-color: rgb(88, 88, 138);
  cursor: default;
  p {
    color: white;
  }
}
