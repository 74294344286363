.goBackContainer {

  display: flex;
  /* justify-content: flex-start; */
  justify-content: center;
  font-size: 16px;
  align-items: center;
  margin-right: 20px;
  width: 80px;
  cursor: pointer;
  svg {
    height: 80px;
    width: 80px;
    color: #8d99e5;
  }
  background-color: #dfddfb30;
  border-radius: 52px;
  z-index:1;
}

.fixedPositioning{
  position:fixed;
  left:4px;
}

.selectedActiveProductionElement {
  background-color: rgba(78, 195, 195, 0.123);
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.739);
}
