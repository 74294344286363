.container {
    background-color: white;
    @media only screen and (min-width: 540px) {
      height: 100px;
    }
  
    @media only screen and (max-width: 540px) {
      /* height: 195px; */
    }
    min-height: 100px;
    position:relative;
    
  
  }
  
  .lineBreaker {
    width: 100%;
    height: 2px;
    background-color: rgba(88, 86, 86, 0.096);
  }
  
  .confirmSaveLater{
    --background:rgb(205, 152, 86);
  }


  .confirmButton{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttonsContainer {

    height: 100%;

    @media only screen and (min-width: 541px) {
      display: flex;
      justify-content: center;
      align-items: center;

  
    }
  
    @media only screen and (max-width: 540px) {
      
    }
  }
  
  .verifyingStockLabel {
    display: flex;
    justify-content: center;
    margin-top: 2px;
  }
  
  .customConfirmationContainer {
    > :nth-child(1) {
      min-height: 80px;
      height: 5vh;
  
      /* width: 100%;
          max-width: 180px;
          min-width: 100px;*/
      font-size: 20px;
      margin: 10px;
    }
  }
  .halfSpace {
  }
  