.lotElement{
    border-radius:10px;
    border-color:rgb(86, 86, 108);
    border-width:1px;
    border-style:solid;
    padding:10px;
    margin:10px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
}

.lotElement:hover{
    cursor:pointer;
    background-color: rgb(190, 182, 227);
}

.lotsContainer{
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
}

.qtyElem{
    font-size:13px;
    text-align: center;
    margin-bottom:5px;
    margin-top:5px;
}

.qtyLabel{
    padding:3px;
    text-align: center;
    background-color: #e4e4ff;
    font-size:23px;
    border-radius:10px;

}

.expiredLot{
    background-color: #ffb7b7;
}

.expiredLotIcon{
    margin-top:10px;
    color:#f67474;
    text-align: center;
}

.whLocTool{
    background-color: rgb(30, 30, 208);
    color: white;
    font-size: 20px;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
}
.whTool{
    background-color: rgb(20, 20, 66);
    color: white;
    font-size: 16px;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
}

.selectedLot{
    background-color: #a2adff;
    border-width:3px;
}

.btnConfirm{
    font-size:30px;
}