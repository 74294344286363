
@use "/src/theme/haxify.scss" as hxf;


.content {
  padding: 5px;
  background-color: white;
  width: 85vw;
  
  min-width: 330px;
  max-width: 600px;
  max-height: 615px;
  overflow: auto;
}

.activeProductionElement {
  margin-top: 20px;
  margin-bottom: 20px;
}
.activeProductionsContainer {
  display: flex;
  justify-content: center;
}

.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}

.notSelectableProduction {
  background-color: rgba(133, 40, 40, 0.384);
  cursor: not-allowed;
}
.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: -5px;
    width: 100px;
    height: 100px;
    color:hxf.$btn-bg-icons-colour;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);
  width: 300px;
  height: 133px;
  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.elemElementHover:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
}

.elemElementTitle {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedActiveProductionElement {
  background-color: rgba(78, 195, 195, 0.123);
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.739);
}

.activeProductionsSection {
  margin-left: 20px;
}

.progressBarContainer {
  position: absolute;
  bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.barLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
  }
}
.fillerBar {
  overflow: hidden;
  position: relative;
  background-color:  rgb(43 37 141 / 25%);
  /*width:50%;*/
  height: 29px;
  left: -1px;
  z-index: 0;
  border-width: 1px;
  border-radius: 25px;
}
.elemElementDescriptionBar {
  color: black;

  text-align: center;
  font-size: 20px;
  border: solid;
  border-width: 1px;
  border-radius: 25px;
  width: 120px;
  height: 30px;
}

.pgrBr {
  display: flex;
  justify-content: center;
}

.infoBarContainer {
  background-color: white;
  width: 60vw;
  max-width: 600px;
  min-width: 410px;
}

.operationBar {
  height: 46px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(33, 87, 189);
}
.operationBarCode {
  justify-content: center;
  font-size: 16px;
  color: white;
  div {
    margin: 4px;
  }
}
.operationLabel {
  color: white;
  font-weight: 900;
}

.operationBarName {
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
  }
}
.operationCodeAndNameContainer {
  margin-left: 14px;
  width: 70%;
}
.singleLineOverFlowElipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productionOrderBar {
  height: 46px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(18, 93, 233);
}
.productionOrderBarCode {
  justify-content: center;
  font-size: 16px;
  color: white;
  div {
    margin: 4px;
  }
}
.productionOrderLabel {
  color: white;
  font-weight: 900;
}

.productionOrderBarName {
  justify-content: center;
  font-size: 20px;
  color: white;
  div {
    margin: 4px;
  }
}
.productionOrderCodeAndNameContainer {
  margin-left: 14px;
  width: 70%;
}

.flexCenter {
  display: flex;
  justify-content: center;
}
.spaceContentInfoBar {
  padding-bottom: 92px;
}

.productBar {
  height: 46px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(0, 0, 0);
}


.irrActSelect{
    border-radius:16px;
    border-color:black;
    border-width:1px;
    border-style:solid;
    margin:10px;
    padding:10px;
    width:150px;
    height:100px;
    text-align: center;
    align-items: center;
    display:flex;
    justify-content: center;
    background-color: #f4f2f9;
}

.irrActSelect:hover{
    background-color: rgb(128, 145, 191);
    color:white;
    cursor:pointer;
}

.irrActContainer{
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
}

.selectOperActContainer{

    display:flex;
    justify-content: center;
    align-items: center;
}

.selectIrrActContainer{
  display:flex;
  justify-content: flex-start;
  align-items: center;
}

.selIrrDiv{
  width: 100%;
  margin-left: -11%;
  text-align: center;
}


.addedIrregularitys {
  display: flex;
  background-color: white;
  overflow: auto;

  min-width: 380px;
}
.addedIrregularity {
  margin: 5px;
  border-radius: 5px;
  background-color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  color:white;
}

.IrregularityName {

  color: white;
  font-size:10px;
  font-weight:600;
  text-decoration: underline;
}

.smallDescription{
  font-size:10px;
  color:white;
}

.IrregularityRemoveBtnContainer {
  margin: 10px;
}

.IrregularityRemoveBtn {
  width: 50px;
}

.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}

.containerBtnConfirm{
  width: 85vw;
  max-width: 600px;
  background-color: white;

  position:relative;
  min-height: 95px;
  top:-16px;
  border-radius:0px 0px 16px 16px;
}


.buttonsContainer {
  display: flex;
  justify-content: center;

}

.customConfirmationButton {
  min-height: 80px;
  height: 5vh;

  width: 100%;
  max-width: 350px;
  min-width: 175px;
  font-size: 25px;
  margin-bottom: 10px;
}
