.container {
}

.content,.subBarInfo {
  padding: 5px;
  background-color: white;
  width: 54vw;
  min-width: 350px;
  max-height: 400px;
  max-width: 400px;
  overflow: auto;
    padding-bottom:30px;
  
}

.subBarInfo {
  background-color: #2c56c0;

  display: flex;
  justify-content: center;
  font-size: 18px;
  color: white;
  div {
    margin-left: 4px;
    margin-right: 4px;

  }
  padding-top: 10px;
  padding-bottom: 10px;
}
.seletedProductInfoLabels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.closeWindowButton {
  padding-right: 5px;
  padding-left: 5px;
}
.title {
}
.barContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(51, 49, 49);
  color: white;
  width: 100%;
}



